/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import { Container, Row, Col, Card, CardBody, Button, Alert, FormGroup, FormInput, FormFeedback, FormSelect, Modal, ModalHeader, ModalBody } from "shards-react";
import * as CEP_Consulta from 'cep-promise';
import PageTitle from "../components/common/PageTitle";
import Loader from '../components/Loader';
import Formater from "../utils/Formater";
import Validate from "../utils/Validate";
import api from '../service/ApiHandler';
import Toast from '../components/Toast';
import Auth from '../utils/Auth';

const token_cobremais = localStorage.getItem('dG9rZW5fY29icmVtYWlz');

class Conta extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            toast: false,
            toastTitle: '',
            toastContent: '',
            toastConfirm: null,
            toastCancel: null,
            toastSimple: false,
            toastTheme: '',
            loading: true,
            error: '',
            nome: '',
            email: '',
            documento: '',
            celular: '',
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            estado: '',
            cep: '',
            validNome: true,
            validEmail: true,
            validDocumento: true,
            validCelular: true,
            validLogradouro: true,
            validNumero: true,
            validComplemento: true,
            validBairro: true,
            validCidade: true,
            validEstado: true,
            validCEP: true,
            alterarSenha: false,
            senhaAtual: '',
            senhaNova: '',
            senhaConfirma: '',
            validAtual: true,
            validNova: true,
            validConfirma: true
        }
        // Outros
        this.limparForms = this.limparForms.bind(this);
        this.openToast = this.openToast.bind(this);
        this.openConfirm = this.openConfirm.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.buscarCEP = this.buscarCEP.bind(this);
        this.alterarUsuario = this.alterarUsuario.bind(this);
        this.alterarSenha = this.alterarSenha.bind(this);
    }

    openToast({ title, content, confirm, theme }){ this.setState({ toast: true, toastTitle: title ? title : '', toastContent: content ? content : '', toastSimple: true, toastConfirm: confirm ? confirm : null, toastCancel: null, toastTheme: theme ? theme : null }); }
    openConfirm({ title, content, confirm, cancel, theme }){ this.setState({ toast: true, toastTitle: title ? title : 'Atenção', toastContent: content ? content : 'Você tem certeza?', toastSimple: false, toastConfirm: confirm ? confirm : null, toastCancel: cancel ? cancel : null, toastTheme: theme ? theme : null }); }
    showLoader(){ this.setState({ loading: true }); }
    hideLoader(){ this.setState({ loading: false }); }

    limparForms(){ }

    async componentDidMount(){
        await Auth();
        this.setState({ error: '' });
        const response = await api.obterUsuarioToken(token_cobremais);
        if(!response) this.setState({ error: 'Erro de conexão' });
        else if(response.error) this.setState({ error: response.error });
        else if(response.warning) this.setState({ error: response.warning });
        else if(response.usuario){
            this.setState({ 
                nome: response.usuario.nome?response.usuario.nome:'',
                email: response.usuario.email?response.usuario.email:'',
                documento: Formater.formatarDocumento(response.usuario.documento?response.usuario.documento:''),
                celular: Formater.formatarCelular(response.usuario.celular?response.usuario.celular:''),
                logradouro: response.usuario.logradouro?response.usuario.logradouro:'',
                numero: response.usuario.numero?response.usuario.numero:'',
                complemento: response.usuario.complemento?response.usuario.complemento:'',
                bairro: response.usuario.bairro?response.usuario.bairro:'',
                cidade: response.usuario.cidade?response.usuario.cidade:'',
                estado: response.usuario.estado?response.usuario.estado:'',
                cep: Formater.formatCep(response.usuario.cep?response.usuario.cep:'')
            });
        } else this.setState({ error: 'Erro de conexão' });
        setTimeout(() => this.hideLoader(), 500);
    }
    
    buscarCEP(e){
        if(!this.state.cep) return false;
        e = this.state.cep.replace(/\D/g , '');
        if(e.length !== 8) return false;
        this.showLoader();
        CEP_Consulta(e)
            .then((response) => {
                setTimeout(() => this.hideLoader(), 1000);
                this.setState({ 
                    logradouro: response.street,
                    cidade: response.city,
                    bairro: response.neighborhood,
                    estado: response.state,
                    validCEP: true
                });
            })
            .catch((error) => {
                this.setState({ 
                    logradouro: '',
                    cidade: '',
                    bairro: '',
                    estado: '',
                    validCEP: false
                });
                setTimeout(() => this.hideLoader(), 1000);
            });
    }

    async alterarUsuario(){
        if(!this.state.nome || this.state.nome.length <= 4) this.openToast({ title: 'Atenção', content: 'Nome inválido', theme: 'warning' });
        else if(!Validate.validateCelular(this.state.celular)) this.openToast({ title: 'Atenção', content: 'Celular inválido', theme: 'warning' });
        else if(!Validate.validadeCep(this.state.cep)) this.openToast({ title: 'Atenção', content: 'CEP inválido', theme: 'warning' });
        else if(!this.state.logradouro) this.openToast({ title: 'Atenção', content: 'Logradouro inválido', theme: 'warning' });
        else if(!this.state.numero) this.openToast({ title: 'Atenção', content: 'Número inválido', theme: 'warning' });
        else if(!this.state.bairro) this.openToast({ title: 'Atenção', content: 'Bairro inválido', theme: 'warning' });
        else if(!this.state.cidade) this.openToast({ title: 'Atenção', content: 'Cidade inválida', theme: 'warning' });
        else if(!this.state.estado) this.openToast({ title: 'Atenção', content: 'Estado inválido', theme: 'warning' });
        else {
            this.showLoader();
            const response = await api.alterarUsuario({ 
                token: token_cobremais,
                nome: this.state.nome.trim(),
                celular: Formater.cleanNumber(this.state.celular),
                cep: Formater.cleanNumber(this.state.cep),
                logradouro: this.state.logradouro.trim(),
                numero: this.state.numero.trim(),
                complemento: this.state.complemento.trim(),
                bairro: this.state.bairro.trim(),
                cidade: this.state.cidade.trim(),
                estado: this.state.estado.trim()
            });
            setTimeout(() => {
                if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger' });
                else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger' });
                else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning' });
                else if(response.success) this.openToast({ title: 'Atenção', content: response.success, theme: 'success', confirm: () => { window.location.reload();this.showLoader() }});
                else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger' });
                setTimeout(() => this.hideLoader(), 500);
            }, 1500);
        }
    }

    async alterarSenha(){
        if(!this.state.senhaAtual) this.openToast({ title: 'Atenção', content: 'Informe a senha atual', theme: 'warning' });
        else if(!this.state.senhaNova) this.openToast({ title: 'Atenção', content: 'Informe a senha nova', theme: 'warning' });
        else if(this.state.senhaNova.length < 6) this.openToast({ title: 'Atenção', content: 'A senha precisa conter entre 6 e 12 caractéres', theme: 'warning' });
        else if(this.state.senhaNova !== this.state.senhaConfirma) this.openToast({ title: 'Atenção', content: 'A confirmação da senha está errada', theme: 'warning' });
        else {
            this.showLoader();
            const response = await api.alterarSenha({ 
                token: token_cobremais,
                atual: this.state.senhaAtual,
                nova: this.state.senhaNova
            });
            setTimeout(() => {
                if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ alterarSenha: true })});
                else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger', confirm: () => this.setState({ alterarSenha: true })});
                else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning', confirm: () => this.setState({ alterarSenha: true })});
                else if(response.success) this.openToast({ title: 'Atenção', content: response.success, theme: 'success', confirm: () => { window.location.reload();this.showLoader() }});
                else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ alterarSenha: true })});
                setTimeout(() => this.hideLoader(), 500);
            }, 1500);
        }
    }

    render() {

    return (
        <Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Toast show={this.state.toast} title={this.state.toastTitle} content={this.state.toastContent} confirm={this.state.toastConfirm} cancel={this.state.toastCancel} toggle={()=>this.setState({ toast: false })} simple={this.state.toastSimple} theme={this.state.toastTheme} />
            <Loader loading={this.state.loading} />
            <Modal size="sm" open={this.state.alterarSenha} backdrop={true} toggle={() => {}}>
                <ModalHeader>
                    Alterar Senha <i className="material-icons close-modal" onClick={() => this.setState({ alterarSenha: false, senhaAtual: '', senhaConfirma: '', senhaNova: '' })}>close</i>
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <label htmlFor="senhaAtual">Senha Atual</label>
                        <FormInput  
                            type="password"
                            placeholder="..." 
                            required
                            id="senhaAtual" 
                            invalid={!this.state.validAtual} 
                            value={this.state.senhaAtual} 
                            onChange={(e)=> {
                                this.setState({ 
                                    senhaAtual: (e.target.value)
                                })
                                if(e.target.value.length){
                                    this.setState({ 
                                        validAtual: true
                                    })
                                } else {
                                    this.setState({ 
                                        validAtual: false
                                    })
                                }
                            }} 
                            onBlur={(e) => {
                                if(!e.target.value.length) this.setState({ validAtual: false })
                                else this.setState({ validAtual: true });
                            }}
                            maxLength="12"
                        />
                        <FormFeedback>Informe a senha atual</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="senhaNova">Senha Nova</label>
                        <FormInput  
                            type="password"
                            placeholder="..." 
                            required
                            id="senhaNova" 
                            invalid={!this.state.validNova} 
                            value={this.state.senhaNova} 
                            onChange={(e)=> {
                                this.setState({ 
                                    senhaNova: (e.target.value)
                                })
                                if(e.target.value.length >= 6){
                                    this.setState({ 
                                        validNova: true
                                    })
                                } else {
                                    this.setState({ 
                                        validNova: false
                                    })
                                }
                            }} 
                            onBlur={(e) => {
                                if(e.target.value.length < 6) this.setState({ validNova: false })
                                else this.setState({ validNova: true });
                            }}
                            maxLength="12"
                        />
                        <FormFeedback>A senha precisa conter entre 6 e 12 caractéres</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="senhaConfirma">Confirme a Nova Senha</label>
                        <FormInput  
                            type="password"
                            placeholder="..." 
                            required
                            id="senhaConfirma" 
                            invalid={this.state.senhaConfirma !== this.state.senhaNova} 
                            value={this.state.senhaConfirma} 
                            onChange={(e)=> {
                                this.setState({ 
                                    senhaConfirma: (e.target.value)
                                })
                            }}
                            maxLength="12"
                        />
                        <FormFeedback>A confirmação da senha está errada</FormFeedback>
                    </FormGroup>
                    <Button theme="success" onClick={this.alterarSenha}>Alterar Senha</Button>
                </ModalBody>
            </Modal>
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Editar Conta" subtitle="CobreMais" className="text-sm-left" />
            </Row>
            <Row>
                { this.state.error ?
                    <Col className="col-lg" md={12} sm={12}>
                        <Alert theme="danger" style={{borderRadius: 10}}><i className="fa fa-exclamation-circle mx-2"></i> {this.state.error}</Alert>
                    </Col>
                    :
                    <Col lg={8}>
                        <Card small className="mb-4">
                            <CardBody>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <label htmlFor="nome">Nome</label>
                                            <FormInput  
                                                placeholder="..." 
                                                required
                                                id="nome" 
                                                invalid={!this.state.validNome} 
                                                value={this.state.nome} 
                                                onChange={(e)=> {
                                                    this.setState({ 
                                                        nome: Formater.capitalizer(e.target.value)
                                                    })
                                                    if(e.target.value && e.target.value.length > 4){
                                                        this.setState({ 
                                                            validNome: true
                                                        })
                                                    } else {
                                                        this.setState({ 
                                                            validNome: false
                                                        })
                                                    }
                                                }} 
                                                onBlur={(e) => {
                                                    if(!e.target.value || e.target.value.length <= 4) this.setState({ validNome: false })
                                                    else this.setState({ validNome: true });
                                                }}
                                            />
                                            <FormFeedback>Nome Inválido</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <label htmlFor="documento">Documento</label>
                                            <div className="form-control no-select" style={{background: '#f3f3f3'}}>
                                                {Formater.formatarDocumento(this.state.documento)}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <label htmlFor="email">E-mail</label>
                                            <div className="form-control no-select" style={{
                                                wordBreak: 'keep-all',
                                                height: 35,
                                                whiteSpace: 'pre',
                                                overflow: 'hidden',
                                                background: '#f3f3f3'
                                            }}>{this.state.email}</div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <label htmlFor="celular">Celular</label>
                                            <FormInput  
                                                placeholder="..." 
                                                required
                                                id="celular" 
                                                invalid={!this.state.validCelular} 
                                                value={this.state.celular} 
                                                onChange={(e)=> {
                                                    this.setState({ 
                                                        celular: Formater.formatarCelular(e.target.value)
                                                    })
                                                    if(e.target.value && Validate.validateCelular(e.target.value)){
                                                        this.setState({ 
                                                            validCelular: true
                                                        })
                                                    } else {
                                                        this.setState({ 
                                                            validCelular: false
                                                        })
                                                    }
                                                }} 
                                                onBlur={(e) => {
                                                    if(!e.target.value || !Validate.validateCelular(e.target.value)) this.setState({ validCelular: false })
                                                    else this.setState({ validCelular: true });
                                                }}
                                                maxLength={15}
                                            />
                                            <FormFeedback>Celular Inválido</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={3} xs={8}>
                                        <label htmlFor="cep">CEP</label>
                                        <FormInput  
                                            type="tel"
                                            placeholder="..." 
                                            required
                                            id="cep" 
                                            invalid={!this.state.validCEP} 
                                            value={this.state.cep} 
                                            onChange={(e)=> {
                                                this.setState({ 
                                                    cep: Formater.formatCep(e.target.value)
                                                })
                                                if(Validate.validadeCep(e.target.value)){
                                                    this.setState({ 
                                                        validCEP: true
                                                    })
                                                } else {
                                                    this.setState({ 
                                                        validCEP: false
                                                    })
                                                }
                                            }} 
                                            onBlur={(e) => {
                                                if(!Validate.validadeCep(e.target.value)) this.setState({ validCEP: false })
                                                else this.setState({ validCEP: true });
                                            }}
                                            maxLength={9}
                                        />
                                        <FormFeedback>CEP Inválido</FormFeedback>
                                    </Col>
                                    <Col md={3} xs={4}>
                                        <FormGroup>
                                            <Button onClick={this.buscarCEP} style={{marginTop: 27}} disabled={this.state.cep.length !== 9}>Buscar CEP</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={5}>
                                        <FormGroup>
                                            <label htmlFor="logradouro">Logradouro</label>
                                            <FormInput  
                                                placeholder="..." 
                                                required
                                                id="logradouro" 
                                                invalid={!this.state.validLogradouro} 
                                                value={this.state.logradouro} 
                                                onChange={(e)=> {
                                                    this.setState({ 
                                                        logradouro: Formater.capitalizer(e.target.value)
                                                    })
                                                    if(e.target.value){
                                                        this.setState({ 
                                                            validLogradouro: true
                                                        })
                                                    } else {
                                                        this.setState({ 
                                                            validLogradouro: false
                                                        })
                                                    }
                                                }} 
                                                onBlur={(e) => {
                                                    if(!e.target.value) this.setState({ validLogradouro: false })
                                                    else this.setState({ validLogradouro: true });
                                                }}
                                            />
                                            <FormFeedback>Logradouro Inválido</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <label htmlFor="numero">Número</label>
                                            <FormInput  
                                                placeholder="..." 
                                                required
                                                id="numero" 
                                                invalid={!this.state.validNumero} 
                                                value={this.state.numero} 
                                                onChange={(e)=> {
                                                    this.setState({ 
                                                        numero: (e.target.value)
                                                    })
                                                    if(e.target.value){
                                                        this.setState({ 
                                                            validNumero: true
                                                        })
                                                    } else {
                                                        this.setState({ 
                                                            validNumero: false
                                                        })
                                                    }
                                                }} 
                                                onBlur={(e) => {
                                                    if(!e.target.value) this.setState({ validNumero: false })
                                                    else this.setState({ validNumero: true });
                                                }}
                                            />
                                            <FormFeedback>Número Inválido</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <label htmlFor="complemento">Complemento</label>
                                            <FormInput  
                                                placeholder="..." 
                                                required
                                                id="complemento"  
                                                value={this.state.complemento} 
                                                onChange={(e)=> {
                                                    this.setState({ 
                                                        complemento: (e.target.value)
                                                    })
                                                }} 
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <label htmlFor="bairro">Bairro</label>
                                            <FormInput  
                                                placeholder="..." 
                                                required
                                                id="bairro" 
                                                invalid={!this.state.validBairro} 
                                                value={this.state.bairro} 
                                                onChange={(e)=> {
                                                    this.setState({ 
                                                        bairro: (e.target.value)
                                                    })
                                                    if(e.target.value){
                                                        this.setState({ 
                                                            validBairro: true
                                                        })
                                                    } else {
                                                        this.setState({ 
                                                            validBairro: false
                                                        })
                                                    }
                                                }} 
                                                onBlur={(e) => {
                                                    if(!e.target.value) this.setState({ validBairro: false })
                                                    else this.setState({ validBairro: true });
                                                }}
                                            />
                                            <FormFeedback>Bairro Inválido</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <label htmlFor="cidade">Cidade</label>
                                            <FormInput  
                                                placeholder="..." 
                                                required
                                                id="cidade" 
                                                invalid={!this.state.validCidade} 
                                                value={this.state.cidade} 
                                                onChange={(e)=> {
                                                    this.setState({ 
                                                        cidade: (e.target.value)
                                                    })
                                                    if(e.target.value){
                                                        this.setState({ 
                                                            validCidade: true
                                                        })
                                                    } else {
                                                        this.setState({ 
                                                            validCidade: false
                                                        })
                                                    }
                                                }} 
                                                onBlur={(e) => {
                                                    if(!e.target.value) this.setState({ validCidade: false })
                                                    else this.setState({ validCidade: true });
                                                }}
                                            />
                                            <FormFeedback>Cidade Inválida</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <label htmlFor="estado">Estado</label>
                                            <FormSelect  
                                                placeholder="..." 
                                                required
                                                id="estado" 
                                                invalid={!this.state.validEstado} 
                                                value={this.state.estado} 
                                                onChange={(e)=> {
                                                    this.setState({ 
                                                        estado: (e.target.value)
                                                    })
                                                    if(e.target.value){
                                                        this.setState({ 
                                                            validEstado: true
                                                        })
                                                    } else {
                                                        this.setState({ 
                                                            validEstado: false
                                                        })
                                                    }
                                                }} 
                                                onBlur={(e) => {
                                                    if(!e.target.value) this.setState({ validEstado: false })
                                                    else this.setState({ validEstado: true });
                                                }}
                                            >
                                                <option value="0">Selecione</option>
                                                <option value="AC">AC</option>
                                                <option value="AL">AL</option>
                                                <option value="AP">AP</option>
                                                <option value="AM">AM</option>
                                                <option value="BA">BA</option>
                                                <option value="CE">CE</option>
                                                <option value="DF">DF</option>
                                                <option value="ES">ES</option>
                                                <option value="GO">GO</option>
                                                <option value="MA">MA</option>
                                                <option value="MS">MS</option>
                                                <option value="MT">MT</option>
                                                <option value="MG">MG</option>
                                                <option value="PA">PA</option>
                                                <option value="PB">PB</option>
                                                <option value="PR">PR</option>
                                                <option value="PE">PE</option>
                                                <option value="PI">PI</option>
                                                <option value="RJ">RJ</option>
                                                <option value="RN">RN</option>
                                                <option value="RS">RS</option>
                                                <option value="RO">RO</option>
                                                <option value="RR">RR</option>
                                                <option value="SC">SC</option>
                                                <option value="SP">SP</option>
                                                <option value="SE">SE</option>
                                                <option value="TO">TO</option>
                                            </FormSelect>
                                            <FormFeedback>Selecione o Estado</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <Button theme="info" onClick={() => this.setState({ alterarSenha: true })}>Alterar Senha</Button>
                                    </Col>
                                    <Col className="text-right">
                                        <Button theme="success" onClick={this.alterarUsuario}>Salvar</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                }
            </Row>
        </Container>
    );
  }
}

export default Conta;
