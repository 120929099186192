import api from './api';

export default  {
    obterDashboard: async (usuario) => {
        var response = false;
        try {
            response = await api.post('/obter-estatisticas', {usuario});
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    obterUsuario: async (usuario) => {
        var response = false;
        try {
            response = await api.post('/obter-usuario', {usuario});
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    obterUsuarioToken: async (token) => {
        var response = false;
        try {
            response = await api.post('/obter-usuario-token', {token});
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    listarGrupos: async (usuario, ativos = false) => {
        var response = false;
        try {
            response = await api.post('/listar-grupos', {usuario, ativos});
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    listarClientes: async (usuario, ativos = false) => {
        var response = false;
        try {
            response = await api.post('/listar-clientes', {usuario, ativos});
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    obterGrupo: async (dados) => {
        var response = false;
        try {
            response = await api.post('/obter-grupo', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    editarGrupo: async (dados) => {
        var response = false;
        try {
            response = await api.post('/editar-grupo', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    criarGrupo: async (dados) => {
        var response = false;
        try {
            response = await api.post('/criar-grupo', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    obterCliente: async (dados) => {
        var response = false;
        try {
            response = await api.post('/obter-cliente', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    editarCliente: async (dados) => {
        var response = false;
        try {
            response = await api.post('/editar-cliente', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    criarCliente: async (dados) => {
        var response = false;
        try {
            response = await api.post('/criar-cliente', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    listarTemplates: async (usuario, ativos = false) => {
        var response = false;
        try {
            response = await api.post('/listar-templates', {usuario, ativos});
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    obterTemplate: async (dados) => {
        var response = false;
        try {
            response = await api.post('/obter-template', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    criarTemplate: async (dados) => {
        var response = false;
        try {
            response = await api.post('/criar-template', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    editarTemplate: async (dados) => {
        var response = false;
        try {
            response = await api.post('/editar-template', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    pararCampanha: async (dados) => {
        var response = false;
        try {
            response = await api.post('/parar-campanha', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    obterCampanhas: async (usuario) => {
        var response = false;
        try {
            response = await api.post('/listar-campanhas', {usuario});
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    criarCampanha: async (dados) => {
        var response = false;
        try {
            response = await api.post('/criar-campanha', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    obterCampanha: async (dados) => {
        var response = false;
        try {
            response = await api.post('/obter-campanha', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    pararEnvioCampanha: async (dados) => {
        var response = false;
        try {
            response = await api.post('/cancelar-campanha-individual', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    pararCobranca: async (dados) => {
        var response = false;
        try {
            response = await api.post('/parar-cobranca', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    obterCobrancas: async (usuario) => {
        var response = false;
        try {
            response = await api.post('/listar-cobrancas', {usuario});
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    criarCobranca: async (dados) => {
        var response = false;
        try {
            response = await api.post('/criar-cobranca', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    obterCobranca: async (dados) => {
        var response = false;
        try {
            response = await api.post('/obter-cobranca', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    pararEnvioCobranca: async (dados) => {
        var response = false;
        try {
            response = await api.post('/cancelar-cobranca-individual', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    loginUsuario: async (dados) => {
        var response = false;
        try {
            response = await api.post('/login-usuario', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    consultarSaldo: async (usuario) => {
        var response = false;
        try {
            response = await api.post('/consultar-saldo', {usuario});
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    validarToken: async (token) => {
        var response = false;
        try {
            response = await api.post('/validar-token', {token});
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    listarRecargas: async (usuario) => {
        var response = false;
        try {
            response = await api.post('/listar-recargas', {usuario});
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    listarPacotes: async () => {
        var response = false;
        try {
            response = await api.get('/listar-pacotes');
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    pagamentoCartao: async (dados) => {
        var response = false;
        try {
            response = await api.post('/pagamento-cartao', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    pagamentoBoleto: async (dados) => {
        var response = false;
        try {
            response = await api.post('/pagamento-boleto', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    recuperarSenha: async (dados) => {
        var response = false;
        try {
            response = await api.post('/recuperar-senha', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    alterarUsuario: async (dados) => {
        var response = false;
        try {
            response = await api.post('/alterar-usuario', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    alterarSenha: async (dados) => {
        var response = false;
        try {
            response = await api.post('/alterar-senha', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    enviarCSV: async (dados) => {
        var response = false;
        try {
            response = await api.post('/enviar-csv', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    obterVideos: async () => {
        var response = false;
        try {
            response = await api.get('/obter-videos');
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
};