/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import {
  Container, Row,  Col, Card, CardHeader, CardBody, Button, Modal, 
  ModalBody, ModalHeader, InputGroup, InputGroupAddon, InputGroupText, Alert,
  FormInput, FormSelect, FormGroup, FormFeedback, Progress, FormRadio,
  FormTextarea
} from "shards-react";
import Speech from 'speak-tts';
import stripHtml from 'string-strip-html';
import ChatBubble from 'react-chat-bubble';
import ReactQuill from 'react-quill';

import Loader from '../components/Loader';
import Toast from '../components/Toast';
import DatePicker from 'react-date-picker';
import PageTitle from "../components/common/PageTitle";
import Formater from "../utils/Formater";
import Auth from '../utils/Auth';

import api from '../service/ApiHandler';

const speech = new Speech();

const usuario_cobremais = localStorage.getItem('dXN1YXJpb19jb2JyZW1haXM=');

class Campanhas extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            toast: false,
            toastTitle: '',
            toastContent: '',
            toastConfirm: null,
            toastCancel: null,
            toastSimple: false,
            toastTheme: '',
            loading: true,
            error: '',
            pesquisar: '',
            idCampanha: '',
            criarCampanha: false,
            titulo: '',
            validTitulo: true,
            validDetalhes: true,
            inicio: new Date(),
            validInicio: true,
            final: new Date(),
            validFinal: true,
            maxFinal: new Date(),
            idGrupo: 0,
            idCliente: 0,
            validGrupo: true,
            tipoEnvio: 1,
            validTemplates: true,
            campanhas: [],
            templates: [],
            clientes: [],
            grupos: [],
            template: {},
            idTemplate: 0,
            visualizarTemplate: false,
            modeloEscolhido: false,
            validCliente: true,
            conteudoSMS: '',
            conteudoEmail: '',
            assuntoEmail: '',
            conteudoLigacao: '',
            validSMS: true,
            validEmail: true,
            validLigacao: true,
            validAssunto: true,
            editarSMS: false,
            editarEmail: false,
            editarLigacao: false,
            campanha: {},
            visualizarCampanha: false,
            detalhes: '',
            visualizarEmail: false,
            visualizarLigacao: false,
            visualizarSMS: false,
            periodoInicio: '',
            periodoFinal: ''
        };
        this.handleCadastrarCampanha = this.handleCadastrarCampanha.bind(this);
        // Outros
        this.openToast = this.openToast.bind(this);
        this.openConfirm = this.openConfirm.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.pararCampanha = this.pararCampanha.bind(this);
        this.obterTemplate = this.obterTemplate.bind(this);
        this.descartarModelo = this.descartarModelo.bind(this);
        this.fecharSMS = this.fecharSMS.bind(this);
        this.fecharEmail = this.fecharEmail.bind(this);
        this.fecharLigacao = this.fecharLigacao.bind(this);
        this.speak = this.speak.bind(this);
        this.obterCampanha = this.obterCampanha.bind(this);
        this.pararEnvio = this.pararEnvio.bind(this);
        this.speak2 = this.speak2.bind(this);

        speech.init({
            'volume': 1,
            'lang': 'pt-BR',
            'rate': 1,
            'pitch': 1,
            'splitSentences': true
        });
    }

    speak(){
        if(speech.hasBrowserSupport()){
            speech.cancel();
            speech.speak({
                text: this.state.conteudoLigacao,
            }).then(() => {})
            .catch(e => {
                console.error(e);
                this.openToast({ title: 'Atenção', content: 'Erro ao reproduzir o áudio', theme: 'danger' });
            })
        } else this.openToast({ title: 'Atenção', content: 'Seu navegador não suporta essa função', theme: 'danger' });
    }

    speak2(){
        if(speech.hasBrowserSupport()){
            speech.cancel();
            speech.speak({
                text: this.state.campanha.campanha.ligacao,
            }).then(() => {})
            .catch(e => {
                console.error(e);
                this.openToast({ title: 'Atenção', content: 'Erro ao reproduzir o áudio', theme: 'danger' });
            })
        } else this.openToast({ title: 'Atenção', content: 'Seu navegador não suporta essa função', theme: 'danger' });
    }

    openToast({ title, content, confirm, theme }){ this.setState({ toast: true, toastTitle: title ? title : '', toastContent: content ? content : '', toastSimple: true, toastConfirm: confirm ? confirm : null, toastCancel: null, toastTheme: theme ? theme : null }); }
    openConfirm({ title, content, confirm, cancel, theme }){ this.setState({ toast: true, toastTitle: title ? title : 'Atenção', toastContent: content ? content : 'Você tem certeza?', toastSimple: false, toastConfirm: confirm ? confirm : null, toastCancel: cancel ? cancel : null, toastTheme: theme ? theme : null }); }
    showLoader(){ this.setState({ loading: true }); }
    hideLoader(){ this.setState({ loading: false }); }

    fecharSMS(){
        if(this.state.conteudoSMS && this.state.conteudoSMS.length <= 4){
            this.openToast({ title: 'Atenção', content: 'SMS inválido', theme: 'warning' });
            this.setState({ validSMS: false });
        } 
        else this.setState({ editarSMS: false, criarCampanha: true });
    }

    fecharLigacao(){
        if(this.state.conteudoLigacao && this.state.conteudoLigacao.length <= 4){
            this.openToast({ title: 'Atenção', content: 'Mensagem inválida', theme: 'warning' });
            this.setState({ validLigacao: false });
        } 
        else this.setState({ editarLigacao: false, criarCampanha: true });
    }

    fecharEmail(){
        if(this.state.assuntoEmail && this.state.assuntoEmail.length <= 4){
            this.openToast({ title: 'Atenção', content: 'Assunto inválido', theme: 'warning' });
            this.setState({ validAssunto: false });
        } else if(stripHtml(this.state.conteudoEmail).result && stripHtml(this.state.conteudoEmail).result.length <= 4){
            this.openToast({ title: 'Atenção', content: 'Preencha o conteúdo do e-mail', theme: 'warning' });
            this.setState({ validEmail: false });
        } 
        else if(stripHtml(this.state.conteudoEmail).result && this.state.assuntoEmail.length <= 4) {
            this.openToast({ title: 'Atenção', content: 'Assunto inválido', theme: 'warning' });
            this.setState({ validAssunto: false });
        }
        else if(this.state.assuntoEmail && stripHtml(this.state.conteudoEmail).result.length <= 4) {
            this.openToast({ title: 'Atenção', content: 'Preencha o conteúdo do e-mail', theme: 'warning' });
            this.setState({ validEmail: false });
        }
        else this.setState({ editarEmail: false, criarCampanha: true });
    }

    async componentDidMount(){
        await Auth();
        var d = new Date();
        d.setDate(d.getDate() + 7);
        this.setState({
            maxFinal: d,
            final: new Date()
        });
        const response = await api.obterCampanhas(usuario_cobremais);
        if(!response) this.setState({ error: 'Erro de conexão' });
        else if(response.error) this.setState({ error: response.error });
        else if(response.warning) this.setState({ error: response.warning });
        else if(response.campanhas) {
            const response2 = await api.listarTemplates(usuario_cobremais, true);
            if(!response2) this.setState({ error: 'Erro de conexão' });
            else if(response2.error) this.setState({ error: response2.error });
            else if(response2.warning) this.setState({ error: response2.warning });
            else if(response2.templates){
                const response3 = await api.listarGrupos(usuario_cobremais, true);
                if(!response3) this.setState({ error: 'Erro de conexão' });
                else if(response3.error) this.setState({ error: response3.error });
                else if(response3.warning) this.setState({ error: response3.warning });
                else if(response3.grupos){
                    const response4 = await api.listarClientes(usuario_cobremais, true);
                    if(!response4) this.setState({ error: 'Erro de conexão' });
                    else if(response4.error) this.setState({ error: response4.error });
                    else if(response4.warning) this.setState({ error: response4.warning });
                    else if(response4.clientes){
                        var clientes = [];
                        for(const cliente of response4.clientes){
                            if(cliente.total_contatos) clientes.push(cliente);
                        }
                        this.setState({ 
                            campanhas: response.campanhas,
                            templates: response2.templates,
                            grupos: response3.grupos,
                            clientes
                        });
                    } else this.setState({ error: 'Erro de conexão' });
                } else this.setState({ error: 'Erro de conexão' });
            } else this.setState({ error: 'Erro de conexão' });
        } else this.setState({ error: 'Erro de conexão' });
        setTimeout(() => this.hideLoader(), 500);
    }

    pararCampanha(id){
        this.openConfirm({ title: 'Você tem certeza?', content: 'Os envios serão cancelados!', confirm: async () => {
            this.showLoader();
            const response = await api.pararCampanha({ id, usuario: usuario_cobremais });
            setTimeout(() => {
                if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger' });
                else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger' });
                else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning' });
                else if(response.success) this.openToast({ title: 'Atenção', content: response.success, theme: 'success', confirm: () => {window.location.reload();this.showLoader()}});
                else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger' });
                this.hideLoader();
            }, 1500);
        }, theme: 'question' });
    }

    pararEnvio(id, campanha){
        this.openConfirm({ title: 'Você tem certeza?', content: 'O envio será cancelado!', confirm: async () => {
            this.showLoader();
            this.setState({ visualizarCampanha: false });
            const response = await api.pararEnvioCampanha({ id, usuario: usuario_cobremais, campanha });
            setTimeout(() => {
                if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ visualizarCampanha: true, loading: false })});
                else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger', confirm: () => this.setState({ visualizarCampanha: true, loading: false })});
                else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning', confirm: () => this.setState({ visualizarCampanha: true, loading: false })});
                else if(response.success) this.openToast({ title: 'Atenção', content: response.success, theme: 'success', confirm: () => this.obterCampanha(campanha)});
                else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ visualizarCampanha: true, loading: false })});
            }, 1500);
        }, theme: 'question' });
    }

    async obterTemplate(id){
        this.showLoader();
        const response = await api.obterTemplate({ id, usuario: usuario_cobremais });
        setTimeout(() => {
            if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ idTemplate: 0 })});
            else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger', confirm: () => this.setState({ idTemplate: 0 }) });
            else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning', confirm: () => this.setState({ idTemplate: 0 }) });
            else if(response.template){
                this.setState({ 
                    modeloEscolhido: true,
                    conteudoSMS: response.template.sms ? response.template.sms : '',
                    conteudoEmail: response.template.email ? response.template.email.corpo : '',
                    assuntoEmail: response.template.email ? response.template.email.assunto : '',
                    conteudoLigacao: response.template.ligacao ? response.template.ligacao : ''
                });

            } else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ idTemplate: 0 })});
            this.hideLoader();
        }, 1500);
    }

    async obterCampanha(id){
        this.showLoader();
        const response = await api.obterCampanha({ id, usuario: usuario_cobremais });
        setTimeout(() => {
            if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ idTemplate: 0 })});
            else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger', confirm: () => this.setState({ idTemplate: 0 }) });
            else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning', confirm: () => this.setState({ idTemplate: 0 }) });
            else if(response.campanha){
                this.setState({ 
                    campanha: response,
                    visualizarCampanha: true
                });
            } else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ idTemplate: 0 })});
            this.hideLoader();
        }, 1500);
    }

    descartarModelo(){
        this.openConfirm({ title: 'Você tem certeza?', content: 'Os modelos preenchidos serão descartados', confirm: async () => {
            this.setState({ idTemplate: 0, modeloEscolhido: false });
        }});
    }

    async handleCadastrarCampanha() {
        if(!this.state.titulo || this.state.titulo.length <= 4) this.openToast({ title: 'Atenção', content: 'Título inválido', theme: 'warning' });
        else if(!this.state.idGrupo && !this.state.idCliente) this.openToast({ title: 'Atenção', content: 'Escolha um grupo ou um cliente', theme: 'warning' });
        else if(!this.state.conteudoSMS && !this.state.conteudoEmail && !this.state.conteudoLigacao && this.state.modeloEscolhido) this.openToast({ title: 'Atenção', content: 'Escolha um template ou crie um novo', theme: 'warning' });
        else if(!this.state.conteudoSMS && !this.state.conteudoEmail && !this.state.conteudoLigacao && !this.state.modeloEscolhido) this.openToast({ title: 'Atenção', content: 'Preecha pelo menos um modelo', theme: 'warning' });
        else if(!this.state.inicio) this.openToast({ title: 'Atenção', content: 'Informe o prazo inicio', theme: 'warning' });
        else if(!this.state.final) this.openToast({ title: 'Atenção', content: 'Informe o prazo final', theme: 'warning' });
        else if(this.state.detalhes && this.state.detalhes.length <= 4) this.openToast({ title: 'Atenção', content: 'A descrição não é valida', theme: 'warning' });
        else {
            this.showLoader();
            this.setState({ criarCampanha: false });
            const response = await api.criarCampanha({ 
                titulo: this.state.titulo,
                sms: this.state.conteudoSMS,
                ligacao: this.state.conteudoLigacao,
                email: (this.state.assuntoEmail) ? JSON.stringify({ assunto: this.state.assuntoEmail, corpo: this.state.conteudoEmail }) : '',
                detalhes: this.state.detalhes,
                inicio: Formater.formatarDataAmericana(this.state.inicio),
                final: Formater.formatarDataAmericana(this.state.final),
                grupo: this.state.idGrupo ? this.state.idGrupo : '',
                cliente: this.state.idCliente ? this.state.idCliente : '',
                usuario: usuario_cobremais
            });
            setTimeout(async () => {
                if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ criarCampanha: true })});
                else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger', confirm: () => this.setState({ criarCampanha: true }) });
                else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning', confirm: () => this.setState({ criarCampanha: true }) });
                else if(response.success){
                    if(!this.state.idTemplate){
                        await api.criarTemplate({ 
                            titulo: `Template Criado em ${Formater.formatarDataHora(new Date())}`,
                            sms: this.state.conteudoSMS ? this.state.conteudoSMS : '',
                            ligacao: this.state.conteudoLigacao ? this.state.conteudoLigacao : '',
                            email: this.state.assuntoEmail ? JSON.stringify({
                                assunto: this.state.assuntoEmail,
                                corpo: this.state.conteudoEmail
                            }) : '',
                            usuario: usuario_cobremais
                        });
                    }
                    this.openToast({ title: 'Atenção', content: response.success, theme: 'success', confirm: () => { this.showLoader(); setTimeout(() => window.location.reload() , 3000); } });
                } else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ criarCampanha: true }) });
                this.hideLoader();
            }, 1500);
        }
    }

    render() {

    return (
        <Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Loader loading={this.state.loading} />
            <Toast show={this.state.toast} title={this.state.toastTitle} content={this.state.toastContent} confirm={this.state.toastConfirm} cancel={this.state.toastCancel} toggle={()=>this.setState({ toast: false })} simple={this.state.toastSimple} theme={this.state.toastTheme} />
            <Modal size="lg" open={this.state.criarCampanha} backdrop={true} toggle={() => {}}>
                <ModalHeader>
                    Criar Campanha <i className="material-icons close-modal" onClick={() => this.setState({ criarCampanha: false })}>close</i>
                </ModalHeader>
                <ModalBody>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <label htmlFor="titulo">Título</label>
                                <FormInput  
                                    placeholder="..." 
                                    required
                                    id="titulo" 
                                    invalid={!this.state.validTitulo} 
                                    value={this.state.titulo} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            titulo: Formater.capitalizer(e.target.value)
                                        })
                                        if(e.target.value && e.target.value.length > 4){
                                            this.setState({ 
                                                validTitulo: true
                                            })
                                        } else {
                                            this.setState({ 
                                                validTitulo: false
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value || e.target.value.length <= 4) this.setState({ validTitulo: false })
                                        else this.setState({ validTitulo: true });
                                    }}
                                />
                                <FormFeedback>Título Inválido</FormFeedback>
                            </FormGroup>
                            <br />
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={4} lg={3}>
                            <FormRadio name="target" checked={this.state.tipoEnvio === 1} onClick={() => this.setState({ tipoEnvio: 1, idGrupo: 0 })}>Enviar a Grupo</FormRadio>
                            <FormRadio name="target" checked={this.state.tipoEnvio === 2} onClick={() => this.setState({ tipoEnvio: 2, idCliente: 0 })}>Enviar a Cliente</FormRadio>
                        </Col>
                        <Col md={8} lg={6}>
                            <FormGroup>
                                {
                                    this.state.tipoEnvio === 1 ?
                                    <>
                                        {
                                            this.state.grupos.length ?
                                            <>
                                                <FormSelect 
                                                    style={{marginTop: 10}}
                                                    id="grupo"
                                                    invalid={!this.state.validGrupo} 
                                                    onChange={(e) => this.setState({ idGrupo: e.target.value })}
                                                    value={this.state.idGrupo}
                                                    onBlur={(e) => {
                                                        if(!e.target.value) this.setState({ validGrupo: false })
                                                        else this.setState({ validGrupo: true });
                                                    }}
                                                >
                                                    <option value={0}>Selecione o grupo</option>
                                                    {
                                                        this.state.grupos.map(row => row.total_contatos?(
                                                            <option value={row.id} key={row.id}>{row.titulo}</option>
                                                        ):(<React.Fragment key={row.id}></React.Fragment>))
                                                    }
                                                </FormSelect>
                                                <FormFeedback>Escolha um grupo</FormFeedback>
                                            </> : <Alert theme="warning">Nenhum grupo foi criado<br /><small><i className="fa fa-info-circle"></i> &nbsp; É necessário cadastrar o dados de contato dos clientes</small></Alert>
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            this.state.clientes.length ?
                                            <>
                                                <FormSelect 
                                                    style={{marginTop: 10}}
                                                    id="grupo"
                                                    invalid={!this.state.validCliente} 
                                                    onChange={(e) => this.setState({ idCliente: e.target.value })}
                                                    value={this.state.idCliente}
                                                    onBlur={(e) => {
                                                        if(!e.target.value) this.setState({ validCliente: false })
                                                        else this.setState({ validCliente: true });
                                                    }}
                                                >
                                                    <option value={0}>Selecione o cliente</option>
                                                    {
                                                        this.state.clientes.map(row => row.total_contatos?(
                                                            <option value={row.id} key={row.id}>{row.nome}</option>
                                                        ):(<React.Fragment key={row.id}></React.Fragment>))
                                                    }
                                                </FormSelect>
                                                <FormFeedback>Escolha um cliente</FormFeedback>
                                            </> : <Alert theme="warning">Nenhum cliente foi cadastrado<br /><small><i className="fa fa-info-circle"></i> &nbsp; É necessário cadastrar o dados de contato dos clientes</small></Alert>
                                        }
                                    </>
                                }
                            </FormGroup>
                            <br />
                        </Col>
                        {
                            !this.state.modeloEscolhido ?
                            <Col lg={6} md={8}>
                                <FormGroup>
                                    <label htmlFor="template">Escolha um modelo</label>
                                    <InputGroup>
                                        <FormSelect 
                                            id="template"
                                            onChange={(e)=> {
                                                this.setState({ 
                                                    idTemplate: e.target.value
                                                });
                                            }} 
                                            value={this.state.idTemplate}
                                        >
                                            <option value={0}>Nenhum template foi criado</option>
                                            {
                                                this.state.templates.map(row => (
                                                    <option value={row.id} key={row.id}>{row.titulo}</option>
                                                ))
                                            }
                                        </FormSelect>
                                        {this.state.idTemplate > 0 ?
                                            <InputGroupAddon type="append">
                                                <Button theme="info" onClick={() => this.obterTemplate(this.state.idTemplate)}>Escolher</Button>
                                            </InputGroupAddon>
                                        : <></> }
                                    </InputGroup>
                                    <br />
                                    <Button onClick={() => this.setState({ modeloEscolhido: true, idTemplate: 0, conteudoSMS: '', conteudoLigacao: '', conteudoEmail: '', assuntoEmail: '' })} theme="success">Criar Novo</Button>
                                </FormGroup>
                            </Col>
                            :
                            <Col md={12}>
                                <FormGroup>
                                    <label htmlFor="template">Personalizar Modelo</label>
                                    <br />
                                    <Button theme={this.state.conteudoSMS?'primary':'warning'} onClick={() => this.setState({ editarSMS: true, criarCampanha: false })}>{this.state.conteudoSMS?'Editar SMS':'SMS Vazio'}</Button>
                                    <Button theme={this.state.conteudoEmail?'primary':'warning'} onClick={() => this.setState({ editarEmail: true, criarCampanha: false })} style={{marginLeft:15}}>{this.state.conteudoEmail?'Editar E-mail':'E-mail Vazio'}</Button>
                                    <Button theme={this.state.conteudoLigacao?'primary':'warning'} onClick={() => this.setState({ editarLigacao: true, criarCampanha: false })} style={{marginLeft:15}}>{this.state.conteudoLigacao?'Editar Ligação':'Ligação Vazia'}</Button>
                                    <Button theme="warning" size="sm" outline onClick={this.descartarModelo} style={{marginLeft: 25}}>Descartar Modelo</Button>
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                    <h5>Prazo</h5>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <label htmlFor="inicio">Inicio</label>
                                <DatePicker
                                    name="inicio"
                                    className="form-control"
                                    value={this.state.inicio}
                                    minDate={new Date()}
                                    onChange={(date) => {
                                        this.setState({ 
                                            inicio: date
                                        })
                                        if(date){
                                            this.setState({ 
                                                validInicio: true
                                            });
                                            var d = new Date(date);
                                            d.setDate(d.getDate() + 7);
                                            this.setState({
                                                maxFinal: d,
                                                final: date
                                            });
                                        } else {
                                            this.setState({ 
                                                validInicio: false
                                            })
                                        }
                                    }}
                                    locale="pt-BR"
                                    clearIcon={false}
                                    maxDetail="month"
                                    readOnly={true}
                                    format="dd/MM/y"
                                />
                                <FormFeedback>Data Inválida</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <label htmlFor="final">Final</label>
                                <DatePicker
                                    name="final"
                                    className="form-control"
                                    value={this.state.final}
                                    minDate={new Date(this.state.inicio)}
                                    onChange={(date) => {
                                        this.setState({ 
                                            final: date
                                        })
                                        if(date){
                                            this.setState({ 
                                                validFinal: true
                                            });
                                        } else {
                                            this.setState({ 
                                                validFinal: false
                                            })
                                        }
                                    }}
                                    locale="pt-BR"
                                    clearIcon={false}
                                    maxDetail="month"
                                    readOnly={true}
                                    format="dd/MM/y"
                                    maxDate={new Date(this.state.maxFinal)}
                                />
                                <FormFeedback>Data Inválida</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <label htmlFor="detalhes">Descrição (O cliente não verá esta informação)</label>
                                <FormTextarea
                                    id="detalhes"
                                    invalid={!this.state.validDetalhes} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            detalhes: e.target.value
                                        })
                                        if(e.target.value && e.target.value.length <= 4){
                                            this.setState({ 
                                                validDetalhes: true
                                            })
                                        } else {
                                            this.setState({ 
                                                validDetalhes: true
                                            })
                                        }
                                    }} 
                                    value={this.state.detalhes}
                                    onBlur={(e) => {
                                        if(e.target.value && e.target.value.length <= 4) this.setState({ validDetalhes: false })
                                        else this.setState({ validDetalhes: true });
                                    }}
                                    style={{resize: 'none'}}
                                    rows="10"
                                />
                                <FormFeedback>Descrição Inválida</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={12} className="text-right">
                            <Button theme="success" onClick={this.handleCadastrarCampanha}>Iniciar Campanha</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal size="lg" open={this.state.visualizarEmail} backdrop={true} toggle={() => {}}>
                <ModalHeader>
                    Conteúdo do E-mail <i className="material-icons close-modal" onClick={() => this.setState({ visualizarCampanha: true, visualizarEmail: false })}>close</i>
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <label htmlFor="assunto">Assunto</label>
                        <div className="form-control">
                            {this.state.visualizarEmail?this.state.campanha.campanha.email.assunto:''}
                        </div>
                    </FormGroup>
                    <label htmlFor="assunto">Conteúdo</label>
                    <ReactQuill 
                        className="round-quill no-toolbar-quill"
                        theme="snow" 
                        disabled
                        value={this.state.visualizarEmail?this.state.campanha.campanha.email.corpo:''}
                        modules={{
                            toolbar: [
                                [{ 'header': [1, 2, false] }],
                                ['bold', 'italic', 'underline','strike', 'blockquote', 'link', 'size', 'code'],
                                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                ['link', 'image'],
                                ['clean']
                            ],
                        }}
                        formats={[
                            'header',
                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                            'list', 'bullet', 'indent', 'align', 'direction',
                            'link', 'image', 'code-block'
                        ]}
                        style={{height: 300, marginBottom: 0}}
                    />
                </ModalBody>
            </Modal>
            <Modal size="md" open={this.state.visualizarSMS} backdrop={true} toggle={() => {}}>
                <ModalHeader>
                    Conteúdo do SMS <i className="material-icons close-modal" onClick={() => this.setState({ visualizarCampanha: true, visualizarSMS: false })}>close</i>
                </ModalHeader>
                <ModalBody style={{padding:'1rem'}}>
                    <center className="xs-hidden">
                        <ChatBubble 
                            onNewMessage={() =>{}}
                            messages = {[
                                {
                                    "type" : 1,
                                    "image": require('../images/avatars/user.jpg'),
                                    "text": this.state.visualizarSMS?this.state.campanha.campanha.sms:''
                                }
                            ]} 
                        />
                    </center>
                </ModalBody>
            </Modal>
            <Modal size="lg" open={this.state.visualizarLigacao} backdrop={true} toggle={() => {}}>
                <ModalHeader>
                    Conteúdo da Ligação <i className="material-icons close-modal" onClick={() => this.setState({ visualizarCampanha: true, visualizarLigacao: false })}>close</i>
                </ModalHeader>
                <ModalBody>
                    {
                        this.state.visualizarLigacao ?
                        <div style={{marginBottom: 15}}>
                            <Button theme="info" style={{marginRight: 10}} onClick={this.speak2}>
                                <img src={require('../images/play.svg')} alt="..." height="15" />
                            </Button>
                            <Button theme="warning" onClick={speech.cancel}>
                                <img src={require('../images/stop.svg')} alt="..." height="15" />
                            </Button>
                        </div>
                        :
                        <></>
                    }
                    <div className="form-control">
                        {this.state.visualizarLigacao?this.state.campanha.campanha.ligacao:''}
                    </div>
                </ModalBody>
            </Modal>
            <Modal size="lg" open={this.state.visualizarCampanha} backdrop={true} toggle={() => {}}>
                <ModalHeader>
                    Detalhes da Campanha <i className="material-icons close-modal" onClick={() => this.setState({ visualizarCampanha: false })}>close</i>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <h5 style={{margin: 0}}>Titulo</h5>
                            <h4>{this.state.campanha.campanha?this.state.campanha.campanha.titulo:'?'}</h4>
                        </Col>
                        <Col md={12}>
                            <h5 style={{margin: 0}}>Prazo</h5>
                            <h4>{this.state.campanha.campanha?`${Formater.formatarData(this.state.campanha.campanha.inicio)} a ${Formater.formatarData(this.state.campanha.campanha.final)}`:'?'}</h4>
                        </Col>
                        <Col md={6}>
                            <h5 style={{margin: 0}}>Meios</h5>
                            {
                                this.state.campanha.campanha ?
                                <h4>
                                    {
                                        !this.state.campanha.campanha.sms ?
                                        <Button theme="primary" outline disabled><i className={`material-icons ${this.state.campanha.campanha.sms?'text-success':'text-danger'}`}>fiber_manual_record</i> SMS</Button>
                                        :
                                        <Button theme="primary" outline onClick={() => this.setState({ visualizarCampanha: false, visualizarSMS: true })}>
                                            <i className={`material-icons ${this.state.campanha.campanha.sms?'text-success':'text-danger'}`}>fiber_manual_record</i> SMS
                                        </Button>
                                    }
                                    {' '} {' '} {' '}
                                    {
                                        !this.state.campanha.campanha.email ?
                                        <Button theme="primary" outline disabled><i className={`material-icons ${this.state.campanha.campanha.email?'text-success':'text-danger'}`}>fiber_manual_record</i> E-mail</Button>
                                        :
                                        <Button theme="primary" outline onClick={() => this.setState({ visualizarCampanha: false, visualizarEmail: true })}>
                                            <i className={`material-icons ${this.state.campanha.campanha.email?'text-success':'text-danger'}`}>fiber_manual_record</i>  E-mail
                                        </Button>
                                    }
                                    {' '} {' '} {' '}
                                    {
                                        !this.state.campanha.campanha.ligacao ?
                                        <Button theme="primary" outline disabled><i className={`material-icons ${this.state.campanha.campanha.ligacao?'text-success':'text-danger'}`}>fiber_manual_record</i> Ligação</Button>
                                        :
                                        <Button theme="primary" outline onClick={() => this.setState({ visualizarCampanha: false, visualizarLigacao: true })}>
                                            <i className={`material-icons ${this.state.campanha.campanha.ligacao?'text-success':'text-danger'}`}>fiber_manual_record</i> Ligação
                                        </Button>
                                    }
                                </h4>
                                :
                                <h4>?</h4>
                            }
                        </Col>
                        <Col md={6}>
                            <h5 style={{margin: 0}}>Situação</h5>
                            {
                                this.state.campanha.campanha ?
                                <h4>
                                    {this.state.campanha.campanha.status === 0 ? <span className="text-warning">Campanha Agendada</span> : <></>}
                                    {this.state.campanha.campanha.status === 1 ? <span className="text-success">Campanha Finalizada</span> : <></>}
                                    {this.state.campanha.campanha.status === 2 ? <span className="text-danger">Campanha Cancelada</span> : <></>}
                                    {this.state.campanha.campanha.status === 3 ? <span className="text-primary">Campanha Encerrada</span> : <></>}
                                </h4>
                                :
                                <h4>?</h4>
                            }
                        </Col>
                        {
                            this.state.campanha.campanha ?
                            <>
                                {
                                    this.state.campanha.campanha.detalhes ?
                                    <Col md={6}>
                                        <h5 style={{margin: 0}}>Descrição</h5>
                                        <h4>
                                            {this.state.campanha.campanha.detalhes}
                                        </h4>
                                    </Col>
                                    : <></>
                                }
                            </>
                            : <></>
                        }
                    </Row>
                    {
                        this.state.campanha.envios ?
                        <>
                            {
                                this.state.campanha.envios.length ?
                                <>
                                    <br />
                                    <div className="table-responsive" style={{maxHeight: 400}}>
                                        <table  className="table mb-0 table-middle table-hover">
                                            <thead className="bg-dark">
                                                <tr>
                                                    <th scope="col" className="border-0" style={{color: 'white'}}>Nome</th>
                                                    <th scope="col" className="border-0" style={{color: 'white'}}>Tipo</th>
                                                    <th scope="col" className="border-0" style={{color: 'white'}}>Contato</th>
                                                    <th scope="col" className="border-0" style={{color: 'white'}} colSpan={this.state.campanha.campanha.status === 0?'2':'1'}>Situação</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.campanha.envios.map(row => (
                                                        <tr key={row.id}>
                                                            <td>{row.nome}</td>
                                                            <td>
                                                                {row.tipo === 1 ? 'SMS':''}
                                                                {row.tipo === 2 ? 'E-mail':''}
                                                                {row.tipo === 3 ? 'Ligação':''}
                                                            </td>
                                                            <td>{row.contato}</td>
                                                            <td colSpan={this.state.campanha.campanha.status === 0 && row.status !== 3 ?"1":"2"}>
                                                                {row.status === 0 ? <span className="text-warning">Aguardando</span> : <></>}
                                                                {row.status === 1 ? <span className="text-primary">Enviando</span> : <></>}
                                                                {row.status === 2 ? <span className="text-danger">Não Enviado</span> : <></>}
                                                                {row.status === 3 ? <span className="text-danger">Cancelado</span> : <></>}
                                                            </td>
                                                            {   this.state.campanha.campanha.status === 0 && row.status !== 3 ?
                                                                <td style={{width: 140}}>
                                                                    <Button size="sm" theme="danger" block onClick={() => this.pararEnvio(row.id, this.state.campanha.campanha.id)}>Cancelar Envio</Button>
                                                                </td>
                                                                :   <></>
                                                            }
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                : <></>
                            }
                        </> : <></>
                    }
                </ModalBody>
            </Modal>
            <Modal size="lg" open={this.state.editarSMS} backdrop={true} toggle={() => {}}>
                <ModalHeader>
                    Template SMS
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <label htmlFor="sms">Conteúdo do SMS</label>
                        <FormTextarea  
                            placeholder="..." 
                            required
                            id="sms" 
                            invalid={!this.state.validSMS} 
                            value={this.state.conteudoSMS} 
                            rows={10}
                            onChange={(e)=> {
                                this.setState({ 
                                    conteudoSMS: Formater.removeAccents((e.target.value).replace(/\n/g, ' ').replace(/\s+/g,' '))
                                })
                                if(e.target.value && e.target.value.length <= 4){
                                    this.setState({ 
                                        validSMS: false
                                    })
                                } else {
                                    this.setState({ 
                                        validSMS: true
                                    })
                                }
                            }} 
                            onBlur={(e) => {
                                this.setState({ 
                                    conteudoSMS: Formater.removeAccents((e.target.value).replace(/\n/g, ' ').replace(/\s+/g,' ').trim())
                                })
                                if(e.target.value && e.target.value.length <= 4) this.setState({ validSMS: false });
                                else this.setState({ validSMS: true });
                            }}
                            maxLength={250}
                            style={{resize: 'none'}}
                        />
                        <FormFeedback>Mensagem Inválida</FormFeedback>
                    </FormGroup>
                    <Row form>
                        <Col xs={4} md={4}>
                            {this.state.conteudoSMS.trim().length} de 250
                        </Col>
                        <Col xs={8} md={8} className="text-right">
                            <Button theme={this.state.conteudoSMS?'success':'primary'} onClick={this.fecharSMS}>
                                {this.state.conteudoSMS?'Aceitar':'Fechar'}
                            </Button>
                        </Col>
                    </Row>
                    {
                        this.state.conteudoSMS ?
                        <center className="xs-hidden">
                            <hr />
                            <div className="device-wrapper" style={{maxWidth: 500}}>
                                <div className="device" data-device="iPhone5" data-orientation="landscape" data-color="black"  style={{width: '100%'}}>
                                    <div className="screen" style={{backgroundColor: 'white'}}>
                                        <ChatBubble 
                                            onNewMessage={() =>{}}
                                            messages = {[
                                                {
                                                    "type" : 1,
                                                    "image": require('../images/avatars/user.jpg'),
                                                    "text": this.state.conteudoSMS
                                                }
                                            ]} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </center>
                        : <></>
                    }
                </ModalBody>
            </Modal>
            <Modal size="lg" open={this.state.editarEmail} backdrop={true} toggle={() => {}}>
                <ModalHeader>
                    Template E-mail
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <label htmlFor="assunto">Assunto</label>
                        <FormInput  
                            placeholder="..." 
                            required
                            id="assunto" 
                            invalid={!this.state.validAssunto} 
                            value={this.state.assuntoEmail} 
                            onChange={(e)=> {
                                this.setState({ 
                                    assuntoEmail: e.target.value
                                })
                                if(e.target.value && e.target.value.length <= 4){
                                    this.setState({ 
                                        validAssunto: false
                                    })
                                } else {
                                    this.setState({ 
                                        validAssunto: true
                                    })
                                }
                            }} 
                            onBlur={(e) => {
                                this.setState({ assuntoEmail: (e.target.value).trim() });
                                if(e.target.value && e.target.value.length <= 4) this.setState({ validAssunto: false })
                                else this.setState({ validAssunto: true });
                            }}
                        />
                        <FormFeedback>Assunto Inválido</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                    <label>Conteúdo do E-mail</label>
                        <ReactQuill 
                            theme="snow" 
                            value={this.state.conteudoEmail}
                            modules={{
                                toolbar: [
                                    [{ 'header': [1, 2, false] }],
                                    ['bold', 'italic', 'underline','strike', 'blockquote', 'link', 'size', 'code'],
                                    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                    ['link', 'image'],
                                    ['clean']
                                ],
                            }}
                            formats={[
                                'header',
                                'bold', 'italic', 'underline', 'strike', 'blockquote',
                                'list', 'bullet', 'indent', 'align', 'direction',
                                'link', 'image', 'code-block'
                            ]}
                            className={!this.state.validEmail?'invalid-quill':''}
                            style={{height: 300, marginBottom: 60}}
                            onChange={(v) => {
                                this.setState({ conteudoEmail: v.trim() });
                                if(
                                    (stripHtml(v).result && stripHtml(v).result.length <= 4)
                                    ||
                                    (this.state.assuntoEmail && stripHtml(v).result.length <= 4)
                                )   this.setState({ validEmail: false });
                                else this.setState({ validEmail: true });
                            }}
                        />
                        <FormInput id="email" style={{display: 'none'}} invalid={!this.state.validEmail}  value={stripHtml(this.state.conteudoEmail).result} onChange={()=>{}} />
                        <FormFeedback>Preencha o conteúdo do E-mail</FormFeedback>
                    </FormGroup>
                    <Row form>
                        <Col xs={6} md={6} className="text-left">
                            <Button theme="warning" onClick={() => {
                                this.setState({ conteudoEmail: '' });
                                setTimeout(() => this.setState({ conteudoEmail: '' }), 500);
                            }}>
                                Limpar E-mail
                            </Button>
                        </Col>
                        <Col xs={6} md={6} className="text-right">
                            <Button theme={stripHtml(this.state.conteudoEmail).result||this.state.assuntoEmail?'success':'primary'} onClick={this.fecharEmail}>
                                {stripHtml(this.state.conteudoEmail).result||this.state.assuntoEmail?'Aceitar':'Fechar'}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal size="lg" open={this.state.editarLigacao} backdrop={true} toggle={() => {}}>
                <ModalHeader>
                    Template Ligação
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <label htmlFor="sms">Conteúdo da Ligação</label>
                        {
                            this.state.conteudoLigacao ?
                            <div style={{marginBottom: 15}}>
                                <Button theme="info" style={{marginRight: 10}} onClick={this.speak}>
                                    <img src={require('../images/play.svg')} alt="..." height="15" />
                                </Button>
                                <Button theme="warning" onClick={speech.cancel}>
                                    <img src={require('../images/stop.svg')} alt="..." height="15" />
                                </Button>
                            </div>
                            :
                            <></>
                        }
                        <FormTextarea  
                            placeholder="..." 
                            required
                            id="sms" 
                            invalid={!this.state.validLigacao} 
                            value={this.state.conteudoLigacao} 
                            rows={10}
                            onChange={(e)=> {
                                speech.cancel();
                                this.setState({ 
                                    conteudoLigacao: Formater.removeAccents((e.target.value).replace(/\n/g, ' ').replace(/\s+/g,' '))
                                })
                                if(e.target.value && e.target.value.length <= 4){
                                    this.setState({ 
                                        validLigacao: false
                                    })
                                } else {
                                    this.setState({ 
                                        validLigacao: true
                                    })
                                }
                            }} 
                            onBlur={(e) => {
                                this.setState({ 
                                    conteudoLigacao: Formater.removeAccents((e.target.value).replace(/\n/g, ' ').replace(/\s+/g,' ')).trim()
                                })
                                if(e.target.value && e.target.value.length <= 4) this.setState({ validLigacao: false });
                                else this.setState({ validLigacao: true });
                            }}
                            maxLength={250}
                            style={{resize: 'none'}}
                        />
                        <FormFeedback>Mensagem Inválida</FormFeedback>
                    </FormGroup>
                    <Row form>
                        <Col xs={4} md={4}>
                            {this.state.conteudoLigacao.trim().length} de 250
                        </Col>
                        <Col xs={8} md={8} className="text-right">
                            <Button theme={this.state.conteudoLigacao?'success':'primary'} onClick={() => { this.fecharLigacao(); speech.cancel(); }}>
                                {this.state.conteudoLigacao?'Aceitar':'Fechar'}
                            </Button>
                        </Col>
                    </Row>
                    <Alert theme="info" size="sm" style={{marginTop: 10,marginBottom: 0}}><i className="fa fa-exclamation-circle mx-2"></i> O sistema não permite o uso de assentos, utilize recursos ortográficos para formar as assentuações.</Alert>
                </ModalBody>
            </Modal>
            { this.state.error ?
            <Alert className="mb-0" theme="danger" style={{marginLeft:-24,marginRight:-24}}>
                <i className="fa fa-exclamation-circle mx-2"></i> {this.state.error}
            </Alert> 
            : <></> }
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Campanhas" subtitle="CobreMais" className="text-sm-left" />
            </Row>
            <Row>
                <Col md={12}>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <Row>
                                <Col md={6} xs={6}>
                                    <h6 style={{ lineHeight: '33px', marginBottom: 0 }}>
                                        {this.state.campanhas.length} {this.state.campanhas.length===1?'Campanha':'Campanhas'}
                                    </h6>
                                </Col>
                                {
                                    this.state.campanhas.length ?
                                    <>
                                        <Col md={6} xs={6} style={{textAlign: 'right'}}>
                                            <Button className="margin-top-xs" size="sm" theme="success" onClick={() => this.setState({ criarCampanha: true })}>Criar Nova</Button>
                                        </Col>
                                        <Col md={12} style={{padding: 0}}>
                                            <hr style={{marginTop: 10, marginBottom: 0}} />
                                        </Col>
                                        <Col md={4} style={{paddingTop: 10}}>
                                            <strong>Pesquisar</strong>
                                            <InputGroup style={{marginTop: 3}}>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText><i className="material-icons">search</i></InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput placeholder="...." onChange={(e)=>this.setState({ pesquisar: e.target.value })} />
                                            </InputGroup>
                                        </Col>
                                        <Col md={4} style={{paddingTop: 10}}>
                                            <strong>Periodo Inicial</strong>
                                            <DatePicker
                                                name="inicio"
                                                className="form-control input-date"
                                                value={this.state.periodoInicio}
                                                onChange={(date) => this.setState({ periodoInicio: date })}
                                                locale="pt-BR"
                                                format="dd/MM/y"
                                            />
                                        </Col>
                                        <Col md={4} style={{paddingTop: 10}}>
                                            <strong>Periodo Final</strong>
                                            <DatePicker
                                                name="final"
                                                className="form-control input-sm input-date"
                                                value={this.state.periodoFinal}
                                                onChange={(date) => this.setState({ periodoFinal: date })}
                                                locale="pt-BR"
                                                format="dd/MM/y"
                                            />
                                        </Col>
                                    </>
                                    :
                                    <Col md={6} xs={6} style={{textAlign: 'right'}}>
                                        <Button className="margin-top-xs" size="sm" theme="success" onClick={() => this.setState({ criarCampanha: true })}>Criar Nova</Button>
                                    </Col>
                                }
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0 pb-3 table-responsive" style={{ maxHeight: 500 }}>
                            {
                                this.state.campanhas.length ?
                                <table className="table mb-0 table-middle table-hover" style={{marginBottom: 0}}>
                                    <thead className="bg-light">
                                        <tr>
                                            <th scope="col" className="border-0">
                                                Título
                                            </th>
                                            <th scope="col" className="border-0">
                                                Prazo
                                            </th>
                                            <th scope="col" className="border-0">
                                                Criado
                                            </th>
                                            <th scope="col" className="border-0">
                                                Meios
                                            </th>
                                            <th scope="col" className="border-0" colSpan="2">
                                                Situação
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.campanhas.map(row => {
                                                var terms = `${row.titulo} ${Formater.formatarData(row.inicio)} ${Formater.formatarData(row.final)}`;
                                                if(row.status === 0) terms += ' Agendada';
                                                if(row.status === 1) terms += ' Finalizada';
                                                if(row.status === 2) terms += ' Cancelada';
                                                if(row.status === 3) terms += ' Encerrada';
                                                if(row.sms) terms += ' SMS';
                                                if(row.email) terms += ' e-mail';
                                                if(row.email) terms += ' email';
                                                if(row.ligacao) terms += ' ligacao';
                                                if(terms) terms = terms.trim();
                                                if(terms) terms = Formater.lowerize(Formater.removeAccents(terms)).trim();
                                                var pesquisar = this.state.pesquisar;
                                                if(pesquisar) pesquisar = pesquisar.trim();
                                                if(pesquisar) pesquisar = Formater.lowerize(Formater.removeAccents(pesquisar)).trim();
                                                var inicio = new Date(row.inicio);
                                                var final = new Date(row.final);
                                                var pesquisado = (terms.includes(pesquisar) || !pesquisar);
                                                var prazo = true;
                                                if(this.state.periodoInicio && inicio < this.state.periodoInicio) prazo = false;
                                                if(this.state.periodoFinal && final > this.state.periodoFinal) prazo = false;
                                                return pesquisado && prazo ? (
                                                    <React.Fragment key={row.id}>
                                                        <tr className="no-border-bottom">
                                                            <td>{row.titulo}</td>
                                                            <td>{Formater.formatarData(row.inicio)} a {Formater.formatarData(row.final)}</td>
                                                            <td>{Formater.formatarAgo(row.criacao)}</td>
                                                            <td style={{width: 100}}>
                                                                <i className={`material-icons ${row.sms?'text-success':'text-danger'}`}>fiber_manual_record</i> SMS
                                                                <br />
                                                                <i className={`material-icons ${row.email?'text-success':'text-danger'}`}>fiber_manual_record</i> E-mail
                                                                <br />
                                                                <i className={`material-icons ${row.ligacao?'text-success':'text-danger'}`}>fiber_manual_record</i> Ligação
                                                            </td>
                                                            <td>
                                                                {row.status === 0 ? 
                                                                <>
                                                                    <span className="text-warning">Campanha Agendada</span>
                                                                    <br />
                                                                    <Button theme="danger" style={{marginTop: 10}} size="sm" outline onClick={() => this.pararCampanha(row.id)}>Parar Campanha</Button>
                                                                </> 
                                                                : <></>}
                                                                {row.status === 1 ? <span className="text-success">Campanha Finalizada</span> : <></>}
                                                                {row.status === 2 ? <span className="text-danger">Campanha Cancelada</span> : <></>}
                                                                {row.status === 3 ? <span className="text-primary">Campanha Encerrada</span> : <></>}
                                                                {
                                                                    row.nao_enviado_total > 0 ?
                                                                    <div className="text-danger" style={{fontSize: 12}}>
                                                                        { row.nao_enviado_total }
                                                                        { row.nao_enviado_total === 1 ? ' envio ' : ' envios ' } 
                                                                        não { row.nao_enviado_total === 1 ? ' realizado ' : ' realizados ' } 
                                                                    </div> 
                                                                    : 
                                                                    <div></div>
                                                                }
                                                                {
                                                                    row.cancelado_total > 0 ?
                                                                    <div className="text-danger" style={{fontSize: 12}}>
                                                                        { row.cancelado_total }
                                                                        { row.cancelado_total === 1 ? ' envio ' : ' envios ' } 
                                                                        { row.cancelado_total === 1 ? ' cancelado ' : ' cancelados ' } 
                                                                    </div> 
                                                                    : 
                                                                    <div></div>
                                                                }
                                                            </td>
                                                            <td>
                                                                <Button block theme="info" onClick={() => this.obterCampanha(row.id)}>Detalhes</Button>
                                                            </td>
                                                        </tr>
                                                        {
                                                            (row.aguardando_total || row.enviado_total || row.nao_enviado_total || row.cancelado_total) && row.status === 0 ?
                                                            <tr className="no-border-top no-padding" style={{backgroundColor:'white !important'}}>
                                                                <td colSpan="6">
                                                                    <Progress multi animated={true} striped={true} className="progress-campanha">
                                                                        <Progress bar theme="warning" animated value={row.aguardando}>{row.aguardando_total} Aguardando</Progress>
                                                                        <Progress bar theme="success" animated value={row.enviado}>{row.enviado_total} Enviados</Progress>
                                                                        <Progress bar theme="danger" animated value={row.nao_enviado}>{row.nao_enviado_total} Não Enviados</Progress>
                                                                        <Progress bar theme="danger" animated value={row.cancelado}>{row.cancelado_total} Cancelados</Progress>
                                                                    </Progress>
                                                                </td>
                                                            </tr>
                                                            : <></>
                                                        }
                                                    </React.Fragment>
                                                ) :(<React.Fragment key={row.id}></React.Fragment>)
                                            }) 
                                        }
                                    </tbody>
                                </table>
                                :
                                <Alert theme="warning" style={{marginBottom: -16, borderBottomLeftRadius: '.625rem', borderBottomRightRadius: '.625rem', padding: '30px 10px', textAlign: 'center'}}>Nenhuma campanha foi criada</Alert>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
  }
}

export default Campanhas;
