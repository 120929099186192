/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import { 
    Container, Row, Col, Card, CardBody, Alert, ListGroup, ListGroupItem 
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Loader from '../components/Loader';
import api from '../service/ApiHandler';
import Toast from '../components/Toast';
import Auth from '../utils/Auth';

class Tutoriais extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            toast: false,
            toastTitle: '',
            toastContent: '',
            toastConfirm: null,
            toastCancel: null,
            toastSimple: false,
            toastTheme: '',
            loading: true,
            error: '',
            videos: [],
            video: 'https://www.youtube.com/embed/K0vdWfs0k4o'
        };
        // Outros
        this.limparForms = this.limparForms.bind(this);
        this.openToast = this.openToast.bind(this);
        this.openConfirm = this.openConfirm.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
    }

    openToast({ title, content, confirm, theme }){ this.setState({ toast: true, toastTitle: title ? title : '', toastContent: content ? content : '', toastSimple: true, toastConfirm: confirm ? confirm : null, toastCancel: null, toastTheme: theme ? theme : null }); }
    openConfirm({ title, content, confirm, cancel, theme }){ this.setState({ toast: true, toastTitle: title ? title : 'Atenção', toastContent: content ? content : 'Você tem certeza?', toastSimple: false, toastConfirm: confirm ? confirm : null, toastCancel: cancel ? cancel : null, toastTheme: theme ? theme : null }); }
    showLoader(){ this.setState({ loading: true }); }
    hideLoader(){ this.setState({ loading: false }); }

    limparForms(){ }

    async componentDidMount(){
        await Auth();
        this.setState({ error: '' });
        const response = await api.obterVideos();
        if(!response) this.setState({ error: 'Erro de conexão' });
        else if(response.error) this.setState({ error: response.error });
        else if(response.warning) this.setState({ error: response.warning });
        else if(response.videos){
            this.setState({ videos: response.videos });
        } else this.setState({ error: 'Erro de conexão' });
        setTimeout(() => this.hideLoader(), 500);
    }

    render() {

    return (
        <Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Toast show={this.state.toast} title={this.state.toastTitle} content={this.state.toastContent} confirm={this.state.toastConfirm} cancel={this.state.toastCancel} toggle={()=>this.setState({ toast: false })} simple={this.state.toastSimple} theme={this.state.toastTheme} />
            <Loader loading={this.state.loading} />
            { this.state.error ?
            <Alert className="mb-0" theme="danger" style={{marginLeft:-24,marginRight:-24}}>
                <i className="fa fa-exclamation-circle mx-2"></i> {this.state.error}
            </Alert> 
            : <></> }
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Tutoriais" subtitle="CobreMais" className="text-sm-left" />
            </Row>
            <Row>
                { this.state.error ?
                    <Col className="col-lg" md={12} sm={12}>
                        <Alert theme="danger" style={{borderRadius: 10}}>{this.state.error}</Alert>
                    </Col>
                    : <></>
                }
            </Row>
            <Row>
                <Col md={12}>
                    <Card small className="mb-4" style={{ borderRadius: 0 }}>
                        <CardBody className="p-0" style={{  borderRadius: 0 }}>
                            {
                                this.state.videos.length ?
                                <Row style={{margin: 0, marginBottom: -5}}>
                                    <Col md={4} style={{padding: 0}}>
                                        <ListGroup className="list-group-videos" style={{
                                            height: 400, overflowY: 'auto'
                                        }}>
                                            {
                                                this.state.videos.map((row, index) => (
                                                    <ListGroupItem 
                                                        className={`list-group-link ${this.state.video===row.link?'active':''}`}
                                                        onClick={() => { this.setState({ video: row.link }) }}
                                                    >
                                                        <span style={{ color: this.state.video === row.link ? '#fff' : '#000' }}>
                                                            {row.titulo.replace(/ - CobreMais/g, '')}
                                                        </span>
                                                    </ListGroupItem>
                                                ))
                                            }
                                        </ListGroup>
                                    </Col>
                                    <Col md={8} style={{padding: 0}}>
                                        <iframe 
                                            title="Tutoriais"
                                            src={`${this.state.video}`} 
                                            frameborder="0" 
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                            allowfullscreen
                                            style={{
                                                width: '100%',
                                                height: 400
                                            }}
                                        ></iframe>
                                    </Col>
                                </Row>
                                :
                                <Alert theme="warning" style={{marginBottom: -16, borderRadius: '.625rem', padding: '30px 10px', textAlign: 'center'}}>Nenhum vídeo disponivel</Alert>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
  }
}

export default Tutoriais;
