import React from "react";
import { Container, Row, Col, Alert, Button } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import SmallStats from "../components/common/SmallStats";
import api from '../service/ApiHandler';
import Loader from '../components/Loader';
import Auth from '../utils/Auth';

const usuario_cobremais = localStorage.getItem('dXN1YXJpb19jb2JyZW1haXM=');

class Dashboard extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      error: '',
      saldo: 0,
      campanhasAtivas: 0,
      campanhasFinalizadas: 0,
      campanhasCanceladas: 0,
      cobrancasAtivas: 0,
      cobrancasFinalizadas: 0,
      cobrancasCanceladas: 0,
      aguardandoEnvioCampanhas: 0,
      totalEnvioCampanhas: 0,
      enviosHojeCampanhas: 0,
      enviosMesCampanhas: 0,
      aguardandoEnvioCobrancas: 0,
      totalEnvioCobrancas: 0,
      enviosHojeCobrancas: 0,
      enviosMesCobrancas: 0,
    };
  }

  async componentDidMount() {
    await Auth();
    const response = await api.obterDashboard(usuario_cobremais);
    if(!response) this.setState({error: 'Erro de conexão'});
    else if(response.error) this.setState({error: response.error});
    else if(response.warning) this.setState({error: response.warning});
    else if(response.campanhas){
      this.setState({ 
        saldo: response.saldo,
        campanhasAtivas: response.campanhas.ativas,
        campanhasFinalizadas: response.campanhas.finalizadas,
        campanhasCanceladas: response.campanhas.canceladas,
        cobrancasAtivas: response.cobrancas.ativas,
        cobrancasFinalizadas: response.cobrancas.finalizadas,
        cobrancasCanceladas: response.cobrancas.canceladas,
        aguardandoEnvioCampanhas: response.envio_campanhas.aguardando,
        totalEnvioCampanhas: response.envio_campanhas.total_envios,
        enviosHojeCampanhas: response.envio_campanhas.envios_hoje,
        enviosMesCampanhas: response.envio_campanhas.envios_mes,
        aguardandoEnvioCobrancas: response.envio_cobrancas.aguardando,
        totalEnvioCobrancas: response.envio_cobrancas.total_envios,
        enviosHojeCobrancas: response.envio_cobrancas.envios_hoje,
        enviosMesCobrancas: response.envio_cobrancas.envios_mes,
      });
    } else this.setState({error: 'Erro de conexão'});
    setTimeout(() => this.setState({ loading: false }), 3000);
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        { this.state.error ?
          <Alert className="mb-0" theme="danger" style={{marginLeft:-24,marginRight:-24}}>
            <i className="fa fa-exclamation-circle mx-2"></i> {this.state.error}
          </Alert> 
        : <></> }
        <Loader loading={this.state.loading} />
        <Row noGutters className="page-header py-4">
          <PageTitle title="Dashboard" subtitle="Cobremais" className="text-sm-left mb-3" />
        </Row>
        {
          !this.state.error ?
          <Row>
            <Col className="col-lg mb-4" md={6} lg={4}>
              <SmallStats
                mainClass={`${this.state.saldo?'bg-primary':'bg-danger'} white-color`}
                variation="1"
                chartData={[{data:[]}]}
                chartLabels={[]}
                label={'Saldo'}
                value={(this.state.saldo).toLocaleString('pt-BR', { style: 'decimal', currency: 'BRL', minimumFractionDigits: 0 })}
                percentage={<Button size="sm" small block theme="light" href="/recarga">Comprar Créditos</Button>}
                increase={this.state.saldo > 0}
                decrease={this.state.saldo <= 0}
                hidePercent={true}
              />
            </Col>
          </Row>
          :
          <></>
        }
        <h4>Campanhas</h4>
        <Row>
          <Col className="col-lg mb-4" lg={4} md={4} sm={4}>
            <SmallStats
              mainClass="bg-info white-color"
              variation="1"
              chartData={[{data:[]}]}
              chartLabels={[]}
              label={'Campanhas Ativas'}
              value={this.state.campanhasAtivas.toLocaleString('pt-BR')}
              percentage={''}
              increase={this.state.campanhasAtivas > 0}
              decrease={this.state.campanhasAtivas <= 0}
              hidePercent={true}
            />
          </Col>
          <Col className="col-lg mb-4" lg={4} md={4} sm={4}>
            <SmallStats
              mainClass="bg-success white-color"
              variation="1"
              chartData={[{data:[]}]}
              chartLabels={[]}
              label={'Campanhas Finalizadas'}
              value={this.state.campanhasFinalizadas.toLocaleString('pt-BR')}
              percentage={''}
              increase={this.state.campanhasFinalizadas > 0}
              decrease={this.state.campanhasFinalizadas <= 0}
              hidePercent={true}
            />
          </Col>
          <Col className="col-lg mb-4" lg={4} md={4} sm={4}>
            <SmallStats
              mainClass="bg-danger white-color"
              variation="1"
              chartData={[{data:[]}]}
              chartLabels={[]}
              label={'Campanhas Canceladas'}
              value={this.state.campanhasCanceladas.toLocaleString('pt-BR')}
              percentage={''}
              increase={this.state.campanhasCanceladas > 0}
              decrease={this.state.campanhasCanceladas <= 0}
              hidePercent={true}
            />
          </Col>
        </Row>
        <Row>
          <Col className="col-lg mb-4" lg={3} md={3} sm={3}>
            <SmallStats
              variation="1"
              chartData={[{data:[]}]}
              chartLabels={[]}
              label={'Aguardando Envio'}
              value={this.state.aguardandoEnvioCampanhas.toLocaleString('pt-BR')}
              percentage={''}
              increase={this.state.aguardandoEnvioCampanhas > 0}
              decrease={this.state.aguardandoEnvioCampanhas <= 0}
            />
          </Col>
          <Col className="col-lg mb-4" lg={3} md={3} sm={3}>
            <SmallStats
              variation="1"
              chartData={[{data:[]}]}
              chartLabels={[]}
              label={'Envios Hoje'}
              value={this.state.enviosHojeCampanhas.toLocaleString('pt-BR')}
              percentage={''}
              increase={this.state.enviosHojeCampanhas > 0}
              decrease={this.state.enviosHojeCampanhas <= 0}
            />
          </Col>
          <Col className="col-lg mb-4" lg={3} md={3} sm={3}>
            <SmallStats
              variation="1"
              chartData={[{data:[]}]}
              chartLabels={[]}
              label={'Envios no Mês'}
              value={this.state.enviosMesCampanhas.toLocaleString('pt-BR')}
              percentage={''}
              increase={this.state.enviosMesCampanhas > 0}
              decrease={this.state.enviosMesCampanhas <= 0}
              hidePercent={true}
            />
          </Col>
          <Col className="col-lg mb-4" lg={3} md={3} sm={3}>
            <SmallStats
              variation="1"
              chartData={[{data:[]}]}
              chartLabels={[]}
              label={'Total de Envios'}
              value={this.state.totalEnvioCampanhas.toLocaleString('pt-BR')}
              percentage={''}
              increase={this.state.totalEnvioCampanhas > 0}
              decrease={this.state.totalEnvioCampanhas <= 0}
              hidePercent={true}
            />
          </Col>
        </Row>
        <h4>Cobranças</h4>
        <Row>
          <Col className="col-lg mb-4" lg={4} md={4} sm={4}>
            <SmallStats
              mainClass="bg-info white-color"
              variation="1"
              chartData={[{data:[]}]}
              chartLabels={[]}
              label={'Cobranças Ativas'}
              value={this.state.cobrancasAtivas.toLocaleString('pt-BR')}
              percentage={''}
              increase={this.state.cobrancasAtivas > 0}
              decrease={this.state.cobrancasAtivas <= 0}
              hidePercent={true}
            />
          </Col>
          <Col className="col-lg mb-4" lg={4} md={4} sm={4}>
            <SmallStats
              mainClass="bg-success white-color"
              variation="1"
              chartData={[{data:[]}]}
              chartLabels={[]}
              label={'Cobranças Finalizadas'}
              value={this.state.cobrancasFinalizadas.toLocaleString('pt-BR')}
              percentage={''}
              increase={this.state.cobrancasFinalizadas > 0}
              decrease={this.state.cobrancasFinalizadas <= 0}
              hidePercent={true}
            />
          </Col>
          <Col className="col-lg mb-4" lg={4} md={4} sm={4}>
            <SmallStats
            mainClass="bg-danger white-color"
              variation="1"
              chartData={[{data:[]}]}
              chartLabels={[]}
              label={'Cobranças Canceladas'}
              value={this.state.cobrancasCanceladas.toLocaleString('pt-BR')}
              percentage={''}
              increase={this.state.cobrancasCanceladas > 0}
              decrease={this.state.cobrancasCanceladas <= 0}
              hidePercent={true}
            />
          </Col>
        </Row>
        <Row>
          <Col className="col-lg mb-4" lg={3} md={3} sm={3}>
            <SmallStats
              variation="1"
              chartData={[{data:[]}]}
              chartLabels={[]}
              label={'Aguardando Envio'}
              value={this.state.aguardandoEnvioCobrancas.toLocaleString('pt-BR')}
              percentage={''}
              increase={this.state.aguardandoEnvioCobrancas > 0}
              decrease={this.state.aguardandoEnvioCobrancas <= 0}
              hidePercent={true}
            />
          </Col>
          <Col className="col-lg mb-4" lg={3} md={3} sm={3}>
            <SmallStats
              variation="1"
              chartData={[{data:[]}]}
              chartLabels={[]}
              label={'Envios Hoje'}
              value={this.state.enviosHojeCobrancas.toLocaleString('pt-BR')}
              percentage={''}
              increase={this.state.enviosHojeCobrancas > 0}
              decrease={this.state.enviosHojeCobrancas <= 0}
              hidePercent={true}
            />
          </Col>
          <Col className="col-lg mb-4" lg={3} md={3} sm={3}>
            <SmallStats
              variation="1"
              chartData={[{data:[]}]}
              chartLabels={[]}
              label={'Envios no Mês'}
              value={this.state.enviosMesCobrancas.toLocaleString('pt-BR')}
              percentage={''}
              increase={this.state.enviosMesCobrancas > 0}
              decrease={this.state.enviosMesCobrancas <= 0}
              hidePercent={true}
            />
          </Col>
          <Col className="col-lg mb-4" lg={3} md={3} sm={3}>
            <SmallStats
              variation="1"
              chartData={[{data:[]}]}
              chartLabels={[]}
              label={'Total de Envios'}
              value={this.state.totalEnvioCobrancas.toLocaleString('pt-BR')}
              percentage={''}
              increase={this.state.totalEnvioCobrancas > 0}
              decrease={this.state.totalEnvioCobrancas <= 0}
              hidePercent={true}
            />
          </Col>
        </Row>
      </Container>
    );
  }
};

export default Dashboard;
