import React from 'react';
import { Button, Modal, ModalBody } from "shards-react";

export default ({ title, content, toggle, show, confirm, cancel, simple, theme }) => (
    <div className="modal-alert">
        <Modal open={show} size="sm" toggle={() => {}} backdropClassName="background-alert">
            {
                title || content ?
                <ModalBody style={{textAlign: 'center'}}>
                    {theme === 'danger'?<img src={require('../images/alerts/error.svg')} alt="..." style={{height: 50, marginBottom: 15}} /> :''}
                    {theme === 'success'?<img src={require('../images/alerts/success.svg')} alt="..." style={{height: 50, marginBottom: 15}} /> :''}
                    {theme === 'question'?<img src={require('../images/alerts/question.svg')} alt="..." style={{height: 50, marginBottom: 15}} /> :''}
                    {theme === 'info'?<img src={require('../images/alerts/info.svg')} alt="..." style={{height: 50, marginBottom: 15}} /> :''}
                    {theme === 'warning'?<img src={require('../images/alerts/warning.svg')} alt="..." style={{height: 50, marginBottom: 15}} /> :''}
                    <h5 style={{marginBottom: 0}}>{title?title:''}</h5>
                    {content?<h4 style={{marginBottom: 0}}>{content}</h4>:''}
                    <div style={{ height: 30, display: simple ? 'block' : 'flex', paddingLeft: 20, paddingRight: 20, marginTop: 25 }}>
                        {
                            simple ?
                            <Button theme="info" style={{width: '100%', display: 'block', marginTop: -1}} onClick={() =>{
                                toggle();
                                if(confirm) confirm();
                            }}>Ok</Button>
                            :
                            <>
                                <Button className="button-left" theme="danger" onClick={() =>{
                                    toggle();
                                    if(cancel) cancel();
                                }}>Não</Button>
                                <Button className="button-right" theme="success" onClick={() =>{
                                    toggle();
                                    if(confirm) confirm();
                                }}>Sim</Button>
                            </>
                        }
                    </div>
                </ModalBody>
                : 
                <ModalBody style={{textAlign: 'center'}}>
                    <h4 style={{marginBottom: 0}}>Atenção</h4>
                    <div style={{ height: 60, display: simple ? 'block' : 'flex', paddingLeft: 20, paddingRight: 20 }}>
                        {
                            simple ?
                            <Button theme="info" style={{width: '100%', display: 'block', marginTop: -1}} onClick={() =>{
                                toggle();
                                if(confirm) confirm();
                            }}>Ok</Button>
                            :
                            <>
                                <Button className="button-left" theme="danger" onClick={() =>{
                                    toggle();
                                    if(cancel) cancel();
                                }}>Cancelar</Button>
                                <Button className="button-right" theme="success" onClick={() =>{
                                    toggle();
                                    if(confirm) confirm();
                                }}>Confirmar</Button>
                            </>
                        }
                    </div>
                </ModalBody>
            }
        </Modal>
    </div>
);