/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import {
  Container, Row,  Col, Card, CardHeader, CardBody, Button, Badge, Modal, 
  ModalBody, ModalHeader, InputGroup, InputGroupAddon, InputGroupText,
  FormInput, FormSelect, FormGroup, FormFeedback, FormCheckbox, Alert
} from "shards-react";
import CSVReader from 'react-csv-reader';

import PageTitle from "../components/common/PageTitle";
import Loader from '../components/Loader';
import Toast from '../components/Toast';

import validate from "../utils/Validate";
import Formater from "../utils/Formater";
import api from '../service/ApiHandler';
import Auth from '../utils/Auth';

const usuario_cobremais = localStorage.getItem('dXN1YXJpb19jb2JyZW1haXM=');
const token_cobremais = localStorage.getItem('dG9rZW5fY29icmVtYWlz');

class Clientes extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            toast: false,
            toastTitle: '',
            toastContent: '',
            toastConfirm: null,
            toastCancel: null,
            toastSimple: false,
            toastTheme: '',
            loading: true,
            error: '',
            grupos: [],
            clientes: [],
            idCliente: '',
            idGrupo: '',
            criarCliente: false,
            nome: '',
            validNome: true,
            documento: '',
            validDocumento: true,
            grupo: 0,
            email1: '',
            email2: '',
            email3: '',
            validEmail1: true,
            validEmail2: true,
            validEmail3: true,
            telefone1: '',
            telefone2: '',
            telefone3: '',
            validTelefone1: true,
            validTelefone2: true,
            validTelefone3: true,
            celular1: '',
            celular2: '',
            celular3: '',
            validCelular1: true,
            validCelular2: true,
            validCelular3: true,
            recebeSMS: true,
            recebeEmail: true,
            recebeLigacao: true,
            criarGrupo: false,
            titulo: '',
            validTitulo: true,
            editarGrupo: false,
            grupoAtivo: false,
            clienteAtivo: false,
            escolherCSV: false,
            cadastro: ''
        };
        // Handle
        this.handleCadastrarGrupo = this.handleCadastrarGrupo.bind(this);
        this.handleCadastrarCliente = this.handleCadastrarCliente.bind(this);
        this.handleEditarGrupo = this.handleEditarGrupo.bind(this);
        this.handleEditarCliente = this.handleEditarCliente.bind(this);
        this.handleEscolherCSV = this.handleEscolherCSV.bind(this);
        // Outros
        this.limparForms = this.limparForms.bind(this);
        this.openToast = this.openToast.bind(this);
        this.openConfirm = this.openConfirm.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
    }

    openToast({ title, content, confirm, theme }){ this.setState({ toast: true, toastTitle: title ? title : '', toastContent: content ? content : '', toastSimple: true, toastConfirm: confirm ? confirm : null, toastCancel: null, toastTheme: theme ? theme : null }); }
    openConfirm({ title, content, confirm, cancel, theme }){ this.setState({ toast: true, toastTitle: title ? title : 'Atenção', toastContent: content ? content : 'Você tem certeza?', toastSimple: false, toastConfirm: confirm ? confirm : null, toastCancel: cancel ? cancel : null, toastTheme: theme ? theme : null }); }
    showLoader(){ this.setState({ loading: true }); }
    hideLoader(){ this.setState({ loading: false }); }

    limparForms(){
        this.setState({
            nome: '',
            validNome: true,
            documento: '',
            validDocumento: true,
            grupo: 0,
            email1: '',
            email2: '',
            email3: '',
            validEmail1: true,
            validEmail2: true,
            validEmail3: true,
            telefone1: '',
            telefone2: '',
            telefone3: '',
            validTelefone1: true,
            validTelefone2: true,
            validTelefone3: true,
            celular1: '',
            celular2: '',
            celular3: '',
            validCelular1: true,
            validCelular2: true,
            validCelular3: true,
            recebeSMS: true,
            recebeEmail: true,
            recebeLigacao: true,
            titulo: '',
            validTitulo: true,
            grupoAtivo: true,
            clienteAtivo: true,
            pesquisar: ''
        });
    }

    async componentDidMount() {
        await Auth();
        this.setState({ error: '' });
        const response1 = await api.listarGrupos(usuario_cobremais);
        if(!response1) this.setState({ error: 'Erro de conexão' });
        else if(response1.error) this.setState({ error: response1.error });
        else if(response1.warning) this.setState({ error: response1.warning });
        else if(response1.grupos){
            const response2 = await api.listarClientes(usuario_cobremais);
            if(!response2) this.setState({ error: 'Erro de conexão' });
            else if(response2.error) this.setState({ error: response2.error });
            else if(response2.warning) this.setState({ error: response2.warning });
            else if(response2.clientes) {
                this.setState({ clientes: response2.clientes, grupos: response1.grupos });
                const response3 = await api.obterUsuarioToken(token_cobremais);
                console.log(response3);
                if(!response3) this.setState({ error: 'Erro de conexão' });
                else if(response3.error) this.setState({ error: response3.error });
                else if(response3.warning) this.setState({ error: response3.warning });
                else if(response3.usuario){
                    this.setState({ cadastro: response3.usuario.cadastro })
                } else this.setState({ error: 'Erro de conexão' });
            } else this.setState({ error: 'Erro de conexão' });
        } else this.setState({ error: 'Erro de conexão' });
        setTimeout(() => this.hideLoader(), 500);
    }

    async handleCadastrarCliente() {
        if(!this.state.nome || this.state.nome.length <= 4) this.openToast({ title: 'Atenção', content: 'Informe o Nome', theme: 'warning' });
        else if(this.state.documento && !validate.validateDocumento(this.state.documento)) this.openToast({ title: 'Atenção', content: 'Informe um CPF ou CNPJ válido', theme: 'warning' });
        else if(this.state.email1 && !validate.validateEmail(this.state.email1)) this.openToast({ title: 'Atenção', content: 'O E-mail 1 não é válido', theme: 'warning' });
        else if(this.state.email2 && !validate.validateEmail(this.state.email2)) this.openToast({ title: 'Atenção', content: 'O E-mail 2 não é válido', theme: 'warning' });
        else if(this.state.email3 && !validate.validateEmail(this.state.email3))this.openToast({ title: 'Atenção', content: 'O E-mail 3 não é válido', theme: 'warning' });
        else if(this.state.telefone1 && !validate.validateTelefone(this.state.telefone1)) this.openToast({ title: 'Atenção', content: 'O Telefone 1 não é válido', theme: 'warning' });
        else if(this.state.telefone2 && !validate.validateTelefone(this.state.telefone2)) this.openToast({ title: 'Atenção', content: 'O Telefone 2 não é válido', theme: 'warning' });
        else if(this.state.telefone3 && !validate.validateTelefone(this.state.telefone3)) this.openToast({ title: 'Atenção', content: 'O Telefone 3 não é válido', theme: 'warning' });
        else if(this.state.celular1 && !validate.validateCelular(this.state.celular1)) this.openToast({ title: 'Atenção', content: 'O Celular 1 não é válido', theme: 'warning' });
        else if(this.state.celular2 && !validate.validateCelular(this.state.celular2)) this.openToast({ title: 'Atenção', content: 'O Celular 2 não é válido', theme: 'warning' });
        else if(this.state.celular3 && !validate.validateCelular(this.state.celular3)) this.openToast({ title: 'Atenção', content: 'O Celular 3 não é válido', theme: 'warning' });
        else {
            this.showLoader();
            const response = await api.criarCliente({ 
                nome: this.state.nome,
                documento: Formater.cleanNumber(this.state.documento),
                email1: this.state.email1,
                email2: this.state.email2,
                email3: this.state.email3,
                telefone1: Formater.cleanNumber(this.state.telefone1),
                telefone2: Formater.cleanNumber(this.state.telefone2),
                telefone3: Formater.cleanNumber(this.state.telefone3),
                celular1: Formater.cleanNumber(this.state.celular1),
                celular2: Formater.cleanNumber(this.state.celular2),
                celular3: Formater.cleanNumber(this.state.celular3),
                grupo: this.state.grupo ? this.state.grupo : null,
                sms: this.state.recebeSMS ? 1 : 0,
                email: this.state.recebeEmail ? 1 : 0,
                ligacao: this.state.recebeLigacao ? 1 : 0,
                usuario: usuario_cobremais
            });
            this.setState({ criarCliente: false });
            setTimeout(() => {
                if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ criarCliente: true })});
                else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger', confirm: () => this.setState({ criarCliente: true }) });
                else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning', confirm: () => this.setState({ criarCliente: true }) });
                else if(response.success) this.openToast({ title: 'Atenção', content: response.success, theme: 'success', confirm: () => {window.location.reload();this.showLoader()}});
                else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ criarCliente: true }) });
                setTimeout(() => this.hideLoader(), 500);
            }, 1500);
        }
    }
    
    async handleCadastrarGrupo() {
        if(this.state.titulo.length <= 4) this.openToast({ title: 'Atenção', content: 'Informe o título', theme: 'warning' });
        else {
            this.showLoader();
            const response = await api.criarGrupo({ 
                titulo: this.state.titulo,
                usuario: usuario_cobremais
            });
            this.setState({ criarGrupo: false });
            setTimeout(() => {
                if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ criarGrupo: true })});
                else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger', confirm: () => this.setState({ criarGrupo: true }) });
                else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning', confirm: () => this.setState({ criarGrupo: true }) });
                else if(response.success) this.openToast({ title: 'Atenção', content: response.success, theme: 'success', confirm: () => {window.location.reload();this.showLoader()}});
                else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ criarGrupo: true }) });
                this.hideLoader();
            }, 1500);
        }
    }

    async handleEditarCliente() {
        if(!this.state.nome || this.state.nome.length <= 4) this.openToast({ title: 'Atenção', content: 'Informe o Nome', theme: 'warning' });
        else if(this.state.documento && !validate.validateDocumento(this.state.documento)) this.openToast({ title: 'Atenção', content: 'Informe um CPF ou CNPJ válido', theme: 'warning' });
        else if(this.state.email1 && !validate.validateEmail(this.state.email1)) this.openToast({ title: 'Atenção', content: 'O E-mail 1 não é válido', theme: 'warning' });
        else if(this.state.email2 && !validate.validateEmail(this.state.email2)) this.openToast({ title: 'Atenção', content: 'O E-mail 2 não é válido', theme: 'warning' });
        else if(this.state.email3 && !validate.validateEmail(this.state.email3))this.openToast({ title: 'Atenção', content: 'O E-mail 3 não é válido', theme: 'warning' });
        else if(this.state.telefone1 && !validate.validateTelefone(this.state.telefone1)) this.openToast({ title: 'Atenção', content: 'O Telefone 1 não é válido', theme: 'warning' });
        else if(this.state.telefone2 && !validate.validateTelefone(this.state.telefone2)) this.openToast({ title: 'Atenção', content: 'O Telefone 2 não é válido', theme: 'warning' });
        else if(this.state.telefone3 && !validate.validateTelefone(this.state.telefone3)) this.openToast({ title: 'Atenção', content: 'O Telefone 3 não é válido', theme: 'warning' });
        else if(this.state.celular1 && !validate.validateCelular(this.state.celular1)) this.openToast({ title: 'Atenção', content: 'O Celular 1 não é válido', theme: 'warning' });
        else if(this.state.celular2 && !validate.validateCelular(this.state.celular2)) this.openToast({ title: 'Atenção', content: 'O Celular 2 não é válido', theme: 'warning' });
        else if(this.state.celular3 && !validate.validateCelular(this.state.celular3)) this.openToast({ title: 'Atenção', content: 'O Celular 3 não é válido', theme: 'warning' });
        else {
            this.showLoader();
            const response = await api.editarCliente({ 
                id: this.state.idCliente, 
                nome: this.state.nome,
                documento: Formater.cleanNumber(this.state.documento),
                email1: this.state.email1,
                email2: this.state.email2,
                email3: this.state.email3,
                telefone1: Formater.cleanNumber(this.state.telefone1),
                telefone2: Formater.cleanNumber(this.state.telefone2),
                telefone3: Formater.cleanNumber(this.state.telefone3),
                celular1: Formater.cleanNumber(this.state.celular1),
                celular2: Formater.cleanNumber(this.state.celular2),
                celular3: Formater.cleanNumber(this.state.celular3),
                grupo: this.state.grupo ? this.state.grupo : null,
                status: this.state.clienteAtivo ? 1 : 0,
                sms: this.state.recebeSMS ? 1 : 0,
                email: this.state.recebeEmail ? 1 : 0,
                ligacao: this.state.recebeLigacao ? 1 : 0,
                usuario: usuario_cobremais
            });
            this.setState({ editarCliente: false });
            setTimeout(() => {
                if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ editarCliente: true })});
                else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger', confirm: () => this.setState({ editarCliente: true }) });
                else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning', confirm: () => this.setState({ editarCliente: true }) });
                else if(response.success) this.openToast({ title: 'Atenção', content: response.success, theme: 'success', confirm: () => {window.location.reload();this.showLoader()}});
                else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ editarCliente: true }) });
                setTimeout(() => this.hideLoader(), 500);
            }, 1500);
        }
    }

    async handleEditarGrupo(){
        if(this.state.titulo.length <= 4) this.openToast({ title: 'Atenção', content: 'Informe o título', theme: 'warning' });
        else {
            this.showLoader();
            const response = await api.editarGrupo({ 
                id: this.state.idGrupo, 
                titulo: this.state.titulo,
                status: this.state.grupoAtivo ? 1 : 0,
                usuario: usuario_cobremais 
            });
            this.setState({ editarGrupo: false });
            setTimeout(() => {
                if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ editarGrupo: true })});
                else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger', confirm: () => this.setState({ editarGrupo: true }) });
                else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning', confirm: () => this.setState({ editarGrupo: true }) });
                else if(response.success) this.openToast({ title: 'Atenção', content: response.success, theme: 'success', confirm: () => {window.location.reload();this.showLoader()}});
                else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ editarGrupo: true }) });
                setTimeout(() => this.hideLoader(), 500);
            }, 1500);
        }
    }

    async editarGrupo(id){
        this.showLoader();
        const response = await api.obterGrupo({ id, usuario: usuario_cobremais });
        setTimeout(() => {
            if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger' });
            else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger' });
            else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning' });
            else if(response.grupo){
                this.setState({
                    idGrupo: response.grupo.id,
                    titulo: response.grupo.titulo,
                    editarGrupo: true,
                    grupoAtivo: response.grupo.status
                })
            } else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger' });
            this.hideLoader();
        }, 1500);
    }

    async editarCliente(id){
        this.showLoader();
        const response = await api.obterCliente({ id, usuario: usuario_cobremais });
        setTimeout(() => {
            if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger' });
            else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger' });
            else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning' });
            else if(response.cliente){
                this.setState({
                    idCliente: response.cliente.id,
                    nome: response.cliente.nome,
                    documento: Formater.formatarDocumento(response.cliente.documento),
                    recebeSMS: response.cliente.sms,
                    recebeEmail: response.cliente.email,
                    recebeLigacao: response.cliente.ligacao,
                    grupo: response.cliente.grupo,
                    telefone1: Formater.formatarTelefone(response.cliente.contato.telefone1),
                    telefone2: Formater.formatarTelefone(response.cliente.contato.telefone2),
                    telefone3: Formater.formatarTelefone(response.cliente.contato.telefone3),
                    email1: Formater.lowerize(response.cliente.contato.email1),
                    email2: Formater.lowerize(response.cliente.contato.email2),
                    email3: Formater.lowerize(response.cliente.contato.email3),
                    celular1: Formater.formatarCelular(response.cliente.contato.celular1),
                    celular2: Formater.formatarCelular(response.cliente.contato.celular2),
                    celular3: Formater.formatarCelular(response.cliente.contato.celular3),
                    editarCliente: true,
                    clienteAtivo: response.cliente.status
                });
            } else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger' });
            this.hideLoader();
        }, 1500);
    }

    handleEscolherCSV(csv){
        document.getElementById("inputCSV").value = "";
        if(csv.length){
            this.openConfirm({ title: `${csv.length} clientes foram detectados no arquivo`, content:'Deseja iniciar a importação?', theme: 'question', 
                confirm: async () => {
                    this.showLoader();
                    var response = await api.enviarCSV({ 
                        clientes: csv, usuario: usuario_cobremais
                    });
                    if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.hideLoader()});
                    else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger', confirm: () => this.hideLoader() });
                    else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning', confirm: () => this.hideLoader() });
                    else if(response.success){
                        this.openToast({ title: 'Processo finalizado', content: `Cadastros enviados para processamento\nO retorno será enviado no seu e-mail`, theme: 'info', confirm: () => { this.showLoader(); window.location.reload(); }});
                        this.hideLoader();
                    }
                    else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.hideLoader()});
                }
            });
        } else this.openToast({ title: 'Sem resultados', content: 'O arquivo está vazio', theme: 'warning' });
    }

    handleErrorCSV(){
        this.openToast({ title: 'Arquivo inválido', content: 'Não foi possível ler esse arquivo', theme: 'danger' });
    }

    render() {

    return (
        <Container fluid className="main-content-container px-4">
            { this.state.error ?
            <Alert className="mb-0" theme="danger" style={{marginLeft:-24,marginRight:-24}}>
                <i className="fa fa-exclamation-circle mx-2"></i> {this.state.error}
            </Alert> 
            : <></> }
            <Loader loading={this.state.loading} />
            <Toast show={this.state.toast} title={this.state.toastTitle} content={this.state.toastContent} confirm={this.state.toastConfirm} cancel={this.state.toastCancel} toggle={()=>this.setState({ toast: false })} simple={this.state.toastSimple} theme={this.state.toastTheme} />
            {/* Page Header */}
            <Modal size="lg" open={this.state.escolherCSV} backdrop={true} toggle={()=>{}}>
                <ModalHeader>
                    Enviar Clientes em Massa <i className="material-icons close-modal" onClick={() => { this.limparForms(); this.setState({escolherCSV: false}) }}>close</i>
                </ModalHeader>
                <ModalBody>
                    <h4>Como preencher?</h4>
                    <h5>1. Baixe o template</h5>
                    <a href="https://cobremais-files.s3.amazonaws.com/Template+Clientes.csv" download target="_blank" rel="noopener noreferrer">
                        <Button theme="info">Baixar Template</Button>
                    </a>
                    <br /><br />
                    <h5>
                        2. Abra o arquivo no Excel<br />
                        <small><small>(ou qualquer software para edição de planilhas)</small></small>
                    </h5>
                    <h5>
                        3. Preencha a planilha com os dados dos clientes<br />
                        <small><small>(O campo "grupo" precisa ser preenchido exatamente como está cadastrado)</small></small>
                    </h5>
                    <img src={require('../images/exemplo-csv.png')} alt="Exemplo CSV" width="100%" />
                    <br /><br />
                    <a href="https://cobremais-files.s3.amazonaws.com/Exemplo+Clientes+em+Massa.csv" download target="_blank" rel="noopener noreferrer">Exemplo de CSV preenchido</a>
                    <br /><br />
                    <h5>
                        4. Salve o arquivo no formato CSV
                    </h5>
                    <img src={require('../images/arquivo-csv.png')} alt="Arquivo CSV" width="130" />
                    <br /><br />
                    <h5>
                        5. Clique no botão para selecionar seu arquivo
                    </h5>
                    <label className="btn btn-primary btn-csv">
                        Selecionar Arquivo
                        <CSVReader 
                            onFileLoaded={this.handleEscolherCSV} 
                            style={{display: 'none'}} 
                            onError={this.handleErrorCSV}
                            parserOptions={{
                                header: true,
                                skipEmptyLines: true,
                                transformHeader: header => header.toLowerCase().replace(/\W/g, '_')}
                            }
                            inputId="inputCSV"
                        />
                    </label>
                </ModalBody>
            </Modal>
            <Modal size="lg" open={this.state.criarCliente} backdrop={true} toggle={()=>{}}>
                <ModalHeader>
                    Criar Cliente <i className="material-icons close-modal" onClick={() => { this.limparForms(); this.setState({criarCliente: false}) }}>close</i>
                </ModalHeader>
                <ModalBody>
                    <h5>Dados Cadastrais</h5>
                    <Row form>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="nome_cadastro">Nome</label>
                                <FormInput  
                                    placeholder="..." 
                                    required
                                    id="nome_cadastro" 
                                    invalid={!this.state.validNome} 
                                    value={this.state.nome} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            nome: Formater.capitalizer(e.target.value)
                                        })
                                        if(e.target.value && e.target.value.length > 4){
                                            this.setState({ 
                                                validNome: true
                                            })
                                        } else {
                                            this.setState({ 
                                                validNome: false
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value || e.target.value.length <= 4) this.setState({ validNome: false })
                                        else this.setState({ validNome: true });
                                    }}
                                />
                                <FormFeedback>Nome Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                                <label htmlFor="documento_cadastro">CPF/CNPJ</label>
                                <FormInput  
                                    placeholder="..." 
                                    required
                                    id="documento_cadastro" 
                                    invalid={!this.state.validDocumento} 
                                    value={this.state.documento} 
                                    maxLength={18}
                                    onChange={(e)=> {
                                        this.setState({ 
                                            documento: Formater.formatarDocumento(e.target.value)
                                        });
                                        if(e.target.value && !validate.validateDocumento(e.target.value)){
                                            this.setState({ 
                                                validDocumento: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validDocumento: true
                                            })
                                        }
                                    }}
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validDocumento: true })
                                    }}
                                />
                                <FormFeedback>Documento Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="text-center" lg={3} xs={4}>
                            <FormCheckbox toggle small checked={this.state.recebeSMS} onChange={(e) =>{
                                 this.setState({ recebeSMS: !this.state.recebeSMS })
                            }}>
                                Enviar SMS
                            </FormCheckbox>
                        </Col>
                        <Col className="text-center" lg={3} xs={4}>
                            <FormCheckbox toggle small checked={this.state.recebeLigacao} onChange={(e) =>{
                                 this.setState({ recebeLigacao: !this.state.recebeLigacao })
                            }}>
                                Enviar Ligação
                            </FormCheckbox>
                        </Col>
                        <Col className="text-center" lg={3} xs={4}>
                            <FormCheckbox toggle small checked={this.state.recebeEmail} onChange={(e) =>{
                                 this.setState({ recebeEmail: !this.state.recebeEmail })
                            }}>
                                Enviar E-mail
                            </FormCheckbox>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={5}>
                            <FormGroup>
                                <label htmlFor="grupo_cadastro">Grupo</label>
                                <FormSelect 
                                    id="grupo_cadastro"
                                    onChange={(e)=> {
                                        this.setState({ 
                                            grupo: e.target.value
                                        })}
                                    } 
                                    value={this.state.grupo}
                                >
                                    <option value={0}>Sem grupo</option>
                                    {
                                        this.state.grupos.map(row => (
                                            <option key={row.id} value={row.id}>{row.titulo} ({row.total_contatos} clientes)</option>
                                        ))
                                    }
                                </FormSelect>
                            </FormGroup>
                        </Col>
                    </Row>
                    <h5>Dados de Contato</h5>
                    <Row form>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="email1_cadastro">E-mail 1</label>
                                <FormInput  
                                    type="email"
                                    placeholder="..." 
                                    required
                                    id="email1_cadastro" 
                                    invalid={!this.state.validEmail1} 
                                    value={this.state.email1} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            email1: Formater.formatarEmail(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateEmail(e.target.value)){
                                            this.setState({ 
                                                validEmail1: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validEmail1: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validEmail1: true })
                                    }}
                                />
                                <FormFeedback>E-mail Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="email2_cadastro">E-mail 2</label>
                                <FormInput  
                                    type="email"
                                    placeholder="..." 
                                    required
                                    id="email2_cadastro" 
                                    invalid={!this.state.validEmail2} 
                                    value={this.state.email2} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            email2: Formater.formatarEmail(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateEmail(e.target.value)){
                                            this.setState({ 
                                                validEmail2: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validEmail2: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validEmail2: true })
                                    }}
                                />
                                <FormFeedback>E-mail Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="email3_cadastro">E-mail 3</label>
                                <FormInput  
                                    type="email"
                                    placeholder="..." 
                                    required
                                    id="email3_cadastro" 
                                    invalid={!this.state.validEmail3} 
                                    value={this.state.email3} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            email3: Formater.formatarEmail(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateEmail(e.target.value)){
                                            this.setState({ 
                                                validEmail3: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validEmail3: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validEmail3: true })
                                    }}
                                />
                                <FormFeedback>E-mail Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="telefone1_cadastro">Telefone 1</label>
                                <FormInput  
                                    type="tel"
                                    placeholder="..." 
                                    required
                                    maxLength={14}
                                    id="telefone1_cadastro" 
                                    invalid={!this.state.validTelefone1} 
                                    value={this.state.telefone1} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            telefone1: Formater.formatarTelefone(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateTelefone(e.target.value)){
                                            this.setState({ 
                                                validTelefone1: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validTelefone1: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validTelefone1: true })
                                    }}
                                />
                                <FormFeedback>Telefone Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="telefone2_cadastro">Telefone 2</label>
                                <FormInput  
                                    type="tel"
                                    placeholder="..." 
                                    required
                                    maxLength={14}
                                    id="telefone2_cadastro" 
                                    invalid={!this.state.validTelefone2} 
                                    value={this.state.telefone2} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            telefone2: Formater.formatarTelefone(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateTelefone(e.target.value)){
                                            this.setState({ 
                                                validTelefone2: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validTelefone2: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validTelefone2: true })
                                    }}
                                />
                                <FormFeedback>Telefone Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="telefone3_cadastro">Telefone 3</label>
                                <FormInput  
                                    type="tel"
                                    placeholder="..." 
                                    required
                                    maxLength={14}
                                    id="telefone3_cadastro" 
                                    invalid={!this.state.validTelefone3} 
                                    value={this.state.telefone3} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            telefone3: Formater.formatarTelefone(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateTelefone(e.target.value)){
                                            this.setState({ 
                                                validTelefone3: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validTelefone3: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validTelefone3: true })
                                    }}
                                />
                                <FormFeedback>Telefone Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="celular1_cadastro">Celular 1</label>
                                <FormInput  
                                    type="tel"
                                    placeholder="..." 
                                    required
                                    maxLength={15}
                                    id="celular1_cadastro" 
                                    invalid={!this.state.validCelular1} 
                                    value={this.state.celular1} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            celular1: Formater.formatarCelular(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateCelular(e.target.value)){
                                            this.setState({ 
                                                validCelular1: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validCelular1: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validCelular1: true })
                                    }}
                                />
                                <FormFeedback>Celular Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="celular2_cadastro">Celular 2</label>
                                <FormInput  
                                    type="tel"
                                    placeholder="..." 
                                    required
                                    maxLength={15}
                                    id="celular2_cadastro" 
                                    invalid={!this.state.validCelular2} 
                                    value={this.state.celular2} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            celular2: Formater.formatarCelular(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateCelular(e.target.value)){
                                            this.setState({ 
                                                validCelular2: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validCelular2: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validCelular2: true })
                                    }}
                                />
                                <FormFeedback>Celular Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="celular3_cadastro">Celular 3</label>
                                <FormInput  
                                    type="tel"
                                    placeholder="..." 
                                    required
                                    maxLength={15}
                                    id="celular3_cadastro" 
                                    invalid={!this.state.validCelular3} 
                                    value={this.state.celular3} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            celular3: Formater.formatarCelular(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateCelular(e.target.value)){
                                            this.setState({ 
                                                validCelular3: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validCelular3: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validCelular3: true })
                                    }}
                                />
                                <FormFeedback>Celular Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="text-right">
                            <Button theme="success" onClick={this.handleCadastrarCliente}>Cadastrar</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal size="sm" open={this.state.criarGrupo} backdrop={true} toggle={()=>{}}>
                <ModalHeader>
                    Criar Grupo <i className="material-icons close-modal" onClick={() => { this.limparForms(); this.setState({ criarGrupo: false }) }}>close</i>
                </ModalHeader>
                <ModalBody>
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <label htmlFor="titulo_cadastro">Título</label>
                                <FormInput  
                                    placeholder="..." 
                                    required
                                    id="titulo_cadastro" 
                                    invalid={!this.state.validTitulo} 
                                    value={this.state.titulo} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            titulo: Formater.capitalizer(e.target.value)
                                        })
                                        if(e.target.value && e.target.value.length > 4){
                                            this.setState({ 
                                                validTitulo: true
                                            })
                                        } else {
                                            this.setState({ 
                                                validTitulo: false
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value ||  e.target.value.length <= 4) this.setState({ validTitulo: false })
                                        else this.setState({ validTitulo: true });
                                    }}
                                />
                                <FormFeedback>Título Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="text-right">
                            <Button theme="success" onClick={this.handleCadastrarGrupo}>Cadastrar</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal size="sm" open={this.state.editarGrupo} backdrop={true} toggle={()=>{}}>
                <ModalHeader>
                    Editar Grupo <i className="material-icons close-modal" onClick={() => { this.limparForms(); this.setState({editarGrupo: false})}}>close</i>
                </ModalHeader>
                <ModalBody>
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <label htmlFor="titulo_cadastro">Título</label>
                                <FormInput  
                                    placeholder="..." 
                                    required
                                    id="titulo_cadastro" 
                                    invalid={!this.state.validTitulo} 
                                    value={this.state.titulo} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            titulo: Formater.capitalizer(e.target.value)
                                        })
                                        if(e.target.value && e.target.value.length > 4){
                                            this.setState({ 
                                                validTitulo: true
                                            })
                                        } else {
                                            this.setState({ 
                                                validTitulo: false
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value ||  e.target.value.length <= 4) this.setState({ validTitulo: false })
                                        else this.setState({ validTitulo: true });
                                    }}
                                />
                                <FormFeedback>Título Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="text-left" md={6}>
                            <FormCheckbox toggle small checked={this.state.grupoAtivo} onChange={(e) =>{
                                this.setState({ grupoAtivo: !this.state.grupoAtivo })
                            }}>Ativo</FormCheckbox>
                        </Col>
                        <Col md={6} className="text-right">
                            <Button theme="success" onClick={this.handleEditarGrupo}>Salvar</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal size="lg" open={this.state.editarCliente} backdrop={true} toggle={()=>{}}>
                <ModalHeader>
                    Editar Cliente <i className="material-icons close-modal"  onClick={() => { this.limparForms(); this.setState({editarCliente: false}); }}>close</i>
                </ModalHeader>
                <ModalBody>
                    <h5>Dados Cadastrais</h5>
                    <Row form>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="nome_cadastro">Nome</label>
                                <FormInput  
                                    placeholder="..." 
                                    required
                                    id="nome_cadastro" 
                                    invalid={!this.state.validNome} 
                                    value={this.state.nome} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            nome: Formater.capitalizer(e.target.value)
                                        })
                                        if(e.target.value && e.target.value.length > 4){
                                            this.setState({ 
                                                validNome: true
                                            })
                                        } else {
                                            this.setState({ 
                                                validNome: false
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value || e.target.value.length <= 4) this.setState({ validNome: false })
                                        else this.setState({ validNome: true });
                                    }}
                                />
                                <FormFeedback>Nome Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                                <label htmlFor="documento_cadastro">CPF/CNPJ</label>
                                <FormInput  
                                    placeholder="..." 
                                    required
                                    id="documento_cadastro" 
                                    invalid={!this.state.validDocumento} 
                                    value={this.state.documento} 
                                    maxLength={18}
                                    onChange={(e)=> {
                                        this.setState({ 
                                            documento: Formater.formatarDocumento(e.target.value)
                                        });
                                        if(e.target.value && !validate.validateDocumento(e.target.value)){
                                            this.setState({ 
                                                validDocumento: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validDocumento: true
                                            })
                                        }
                                    }}
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validDocumento: true })
                                    }}
                                />
                                <FormFeedback>Documento Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="text-center" lg={3} xs={4}>
                            <FormCheckbox toggle small checked={this.state.recebeSMS} onChange={(e) =>{
                                 this.setState({ recebeSMS: !this.state.recebeSMS })
                            }}>
                                Enviar SMS
                            </FormCheckbox>
                        </Col>
                        <Col className="text-center" lg={3} xs={4}>
                            <FormCheckbox toggle small checked={this.state.recebeLigacao} onChange={(e) =>{
                                 this.setState({ recebeLigacao: !this.state.recebeLigacao })
                            }}>
                                Enviar Ligação
                            </FormCheckbox>
                        </Col>
                        <Col className="text-center" lg={3} xs={4}>
                            <FormCheckbox toggle small checked={this.state.recebeEmail} onChange={(e) =>{
                                 this.setState({ recebeEmail: !this.state.recebeEmail })
                            }}>
                                Enviar E-mail
                            </FormCheckbox>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={5}>
                            <FormGroup>
                                <label htmlFor="grupo_cadastro">Grupo</label>
                                <FormSelect 
                                    id="grupo_cadastro"
                                    onChange={(e)=> {
                                        this.setState({ 
                                            grupo: e.target.value
                                        })}
                                    } 
                                    value={this.state.grupo}
                                >
                                    <option value={0}>Sem grupo</option>
                                    {
                                        this.state.grupos.map(row => (
                                            <option key={row.id} value={row.id}>{row.titulo} ({row.total_contatos} clientes)</option>
                                        ))
                                    }
                                </FormSelect>
                            </FormGroup>
                        </Col>
                    </Row>
                    <h5>Dados de Contato</h5>
                    <Row form>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="email1_cadastro">E-mail 1</label>
                                <FormInput  
                                    type="email"
                                    placeholder="..." 
                                    required
                                    id="email1_cadastro" 
                                    invalid={!this.state.validEmail1} 
                                    value={this.state.email1} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            email1: Formater.formatarEmail(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateEmail(e.target.value)){
                                            this.setState({ 
                                                validEmail1: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validEmail1: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validEmail1: true })
                                    }}
                                />
                                <FormFeedback>E-mail Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="email2_cadastro">E-mail 2</label>
                                <FormInput  
                                    type="email"
                                    placeholder="..." 
                                    required
                                    id="email2_cadastro" 
                                    invalid={!this.state.validEmail2} 
                                    value={this.state.email2} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            email2: Formater.formatarEmail(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateEmail(e.target.value)){
                                            this.setState({ 
                                                validEmail2: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validEmail2: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validEmail2: true })
                                    }}
                                />
                                <FormFeedback>E-mail Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="email3_cadastro">E-mail 3</label>
                                <FormInput  
                                    type="email"
                                    placeholder="..." 
                                    required
                                    id="email3_cadastro" 
                                    invalid={!this.state.validEmail3} 
                                    value={this.state.email3} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            email3: Formater.formatarEmail(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateEmail(e.target.value)){
                                            this.setState({ 
                                                validEmail3: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validEmail3: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validEmail3: true })
                                    }}
                                />
                                <FormFeedback>E-mail Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="telefone1_cadastro">Telefone 1</label>
                                <FormInput  
                                    type="tel"
                                    placeholder="..." 
                                    required
                                    maxLength={14}
                                    id="telefone1_cadastro" 
                                    invalid={!this.state.validTelefone1} 
                                    value={this.state.telefone1} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            telefone1: Formater.formatarTelefone(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateTelefone(e.target.value)){
                                            this.setState({ 
                                                validTelefone1: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validTelefone1: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validTelefone1: true })
                                    }}
                                />
                                <FormFeedback>Telefone Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="telefone2_cadastro">Telefone 2</label>
                                <FormInput  
                                    type="tel"
                                    placeholder="..." 
                                    required
                                    maxLength={14}
                                    id="telefone2_cadastro" 
                                    invalid={!this.state.validTelefone2} 
                                    value={this.state.telefone2} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            telefone2: Formater.formatarTelefone(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateTelefone(e.target.value)){
                                            this.setState({ 
                                                validTelefone2: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validTelefone2: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validTelefone2: true })
                                    }}
                                />
                                <FormFeedback>Telefone Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="telefone3_cadastro">Telefone 3</label>
                                <FormInput  
                                    type="tel"
                                    placeholder="..." 
                                    required
                                    maxLength={14}
                                    id="telefone3_cadastro" 
                                    invalid={!this.state.validTelefone3} 
                                    value={this.state.telefone3} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            telefone3: Formater.formatarTelefone(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateTelefone(e.target.value)){
                                            this.setState({ 
                                                validTelefone3: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validTelefone3: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validTelefone3: true })
                                    }}
                                />
                                <FormFeedback>Telefone Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="celular1_cadastro">Celular 1</label>
                                <FormInput  
                                    type="tel"
                                    placeholder="..." 
                                    required
                                    maxLength={15}
                                    id="celular1_cadastro" 
                                    invalid={!this.state.validCelular1} 
                                    value={this.state.celular1} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            celular1: Formater.formatarCelular(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateCelular(e.target.value)){
                                            this.setState({ 
                                                validCelular1: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validCelular1: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validCelular1: true })
                                    }}
                                />
                                <FormFeedback>Celular Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="celular2_cadastro">Celular 2</label>
                                <FormInput  
                                    type="tel"
                                    placeholder="..." 
                                    required
                                    maxLength={15}
                                    id="celular2_cadastro" 
                                    invalid={!this.state.validCelular2} 
                                    value={this.state.celular2} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            celular2: Formater.formatarCelular(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateCelular(e.target.value)){
                                            this.setState({ 
                                                validCelular2: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validCelular2: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validCelular2: true })
                                    }}
                                />
                                <FormFeedback>Celular Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label htmlFor="celular3_cadastro">Celular 3</label>
                                <FormInput  
                                    type="tel"
                                    placeholder="..." 
                                    required
                                    maxLength={15}
                                    id="celular3_cadastro" 
                                    invalid={!this.state.validCelular3} 
                                    value={this.state.celular3} 
                                    onChange={(e)=> {
                                        this.setState({ 
                                            celular3: Formater.formatarCelular(e.target.value)
                                        })
                                        if(e.target.value && !validate.validateCelular(e.target.value)){
                                            this.setState({ 
                                                validCelular3: false
                                            })
                                        } else {
                                            this.setState({ 
                                                validCelular3: true
                                            })
                                        }
                                    }} 
                                    onBlur={(e) => {
                                        if(!e.target.value) this.setState({ validCelular3: true })
                                    }}
                                />
                                <FormFeedback>Celular Inválido</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="text-left" md={6} xs={12}>
                            <FormCheckbox toggle small checked={this.state.clienteAtivo} onChange={(e) =>{
                                 this.setState({ clienteAtivo: !this.state.clienteAtivo })
                            }}>Ativo</FormCheckbox>
                        </Col>
                        <Col md={6} className="text-right">
                            <Button theme="success" onClick={this.handleEditarCliente}>Salvar</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" xs="6" title="Clientes" subtitle="CobreMais" className="text-sm-left" />
                <Col sm="8" xs="6" className="text-right">
                    <Button onClick={() => this.setState({ escolherCSV: true })}>Enviar Clientes em Massa</Button><br />
                    <a href="#" onClick={() => this.setState({ escolherCSV: true })}><small>Como enviar clientes em massa?</small></a>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <Row>
                                <Col md={3}>
                                    <div style={{ lineHeight: '38px', marginBottom: 0 }}>
                                        <h6 className="m-0" style={{paddingTop: 8}}>
                                            {this.state.clientes.length} {this.state.clientes.length===1?'Cliente':'Clientes'}
                                        </h6>
                                    </div>
                                </Col>
                                {
                                    this.state.clientes.length ?
                                    <>
                                        <Col md={6}>
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText><i className="material-icons">search</i></InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput placeholder="Pesquisar" onChange={(e)=>this.setState({ pesquisar: e.target.value })} />
                                            </InputGroup>
                                        </Col>
                                        <Col md={3} style={{textAlign: 'right'}}>
                                            <Button className="margin-top-xs" size="sm" theme="success" onClick={() => this.setState({ criarCliente: true })}>Criar Novo</Button>
                                        </Col>
                                    </>
                                    : 
                                    <Col md={9} style={{textAlign: 'right'}}>
                                        <Button className="margin-top-xs" size="sm" theme="success" onClick={() => this.setState({ criarCliente: true })}>Criar Novo</Button>
                                    </Col>
                                }
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0 pb-3 table-responsive" style={{ maxHeight: '40vh' }}>
                            {
                                this.state.clientes.length ?
                                <table className="table mb-0 table-middle table-hover" style={{marginBottom: 0}}>
                                    <thead className="bg-light">
                                        <tr>
                                            <th scope="col" className="border-0">
                                                Nome
                                            </th>
                                            <th scope="col" className="border-0">
                                                Documento
                                            </th>
                                            <th scope="col" className="border-0">
                                                Preferências
                                            </th>
                                            <th scope="col" className="border-0">
                                                Editado
                                            </th>
                                            <th scope="col" className="border-0" colSpan="2">
                                                Grupo
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.clientes.map(row => {
                                                var terms = `${row.nome} ${row.documento} ${row.titulo_grupo?row.titulo_grupo:''} ${row.alteracao?Formater.formatarAgo(row.alteracao):Formater.formatarAgo(row.criacao)}`;
                                                if(terms) terms = terms.trim();
                                                if(terms) terms = Formater.lowerize(Formater.removeAccents(terms)).trim();
                                                var pesquisar = this.state.pesquisar;
                                                if(pesquisar) pesquisar = pesquisar.trim();
                                                if(pesquisar) pesquisar = Formater.lowerize(Formater.removeAccents(pesquisar)).trim();
                                                return terms.includes(pesquisar) || !pesquisar ? (
                                                    <tr key={row.id} style={{opacity:row.status?'1':'.4'}}>
                                                        <td>{row.nome}</td>
                                                        <td>{row.documento?Formater.formatarDocumento(row.documento):'...'}</td>
                                                        <td>
                                                            <i className={`material-icons ${row.sms?'text-success':'text-danger'}`}>fiber_manual_record</i> SMS
                                                            <br />
                                                            <i className={`material-icons ${row.email?'text-success':'text-danger'}`}>fiber_manual_record</i> E-mail
                                                            <br />
                                                            <i className={`material-icons ${row.ligacao?'text-success':'text-danger'}`}>fiber_manual_record</i> Ligação
                                                        </td>
                                                        <td>{row.alteracao?Formater.formatarAgo(row.alteracao):Formater.formatarAgo(row.criacao)}</td>
                                                        <td>
                                                            {
                                                                row.titulo_grupo ?
                                                                <Badge theme="info">{row.titulo_grupo}</Badge>
                                                                :
                                                                <Badge theme="dark">Nenhum</Badge>
                                                            }
                                                        </td>
                                                        <td>
                                                            <Button size="md" onClick={() => this.editarCliente(row.id)}>Editar</Button>
                                                        </td>
                                                    </tr>
                                                ) : (<></>)
                                            })
                                        }
                                    </tbody>
                                </table>
                                :
                                <Alert theme="warning" style={{marginBottom: -16, borderBottomLeftRadius: '.625rem', borderBottomRightRadius: '.625rem', padding: '30px 10px', textAlign: 'center'}}>Nenhum cliente foi cadastrado</Alert>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <Row>
                                <Col md={6}>
                                    <h6 className="m-0">{this.state.grupos.length} {this.state.grupos.length===1?'Grupo':'Grupos'}</h6>
                                </Col>
                                <Col md={6} style={{textAlign: 'right'}}>
                                    <Button className="margin-top-xs" size="sm" theme="success" onClick={()=>this.setState({ criarGrupo: true })}>Criar Novo</Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0 pb-3 table-responsive table-hover" style={{ maxHeight: '40vh' }}>
                            {
                                this.state.grupos.length ?
                                <table className="table mb-0 table-middle" style={{marginBottom: 0}}>
                                    <thead className="bg-light">
                                        <tr>
                                            <th scope="col" className="border-0">
                                                Título
                                            </th>
                                            <th scope="col" className="border-0">
                                                Total de Clientes
                                            </th>
                                            <th scope="col" className="border-0" colSpan="2">
                                                Editado
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.grupos.map(row => (
                                                <tr key={row.id} style={{opacity:row.status?'1':'.4'}}>
                                                    <td>{row.titulo}</td>
                                                    <td>{row.total_contatos}</td>
                                                    <td>{row.alteracao?Formater.formatarAgo(row.alteracao):Formater.formatarAgo(row.criacao)}</td>
                                                    <td className="text-center">
                                                        <Button size="sm" onClick={() => this.editarGrupo(row.id)}>Editar</Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                :
                                <Alert theme="warning" style={{marginBottom: -16, borderBottomLeftRadius: '.625rem', borderBottomRightRadius: '.625rem', padding: '30px 10px', textAlign: 'center'}}>Nenhum grupo foi cadastrado</Alert>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
  }
}

export default Clientes;
