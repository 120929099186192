import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, EmptyLayout } from "./layouts";

// Route Views
import Dashboard from "./views/Dashboard";
import Error from "./views/Error";
import Login from "./views/Login";
import Clientes from "./views/Clientes";
import Campanhas from "./views/Campanhas";
import Cobrancas from "./views/Cobrancas";
import Templates from "./views/Templates";
import Recargas from "./views/Recargas";
import Recarga from "./views/Recarga";
import Conta from "./views/Conta";
import Tutoriais from "./views/Tutoriais";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/tutoriais" />
  },
  {
    path: "/clientes",
    layout: DefaultLayout,
    component: Clientes
  },
  {
    path: "/campanhas",
    layout: DefaultLayout,
    component: Campanhas
  },
  {
    path: "/cobrancas",
    layout: DefaultLayout,
    component: Cobrancas
  },
  {
    path: "/templates",
    layout: DefaultLayout,
    component: Templates
  },
  {
      path: "/dashboard",
      layout: DefaultLayout,
      component: Dashboard
  },
  {
    path: "/login",
    layout: EmptyLayout,
    component: Login
  },
  {
    path: "/error",
    layout: DefaultLayout,
    component: Error
  },
  {
    path: "/recargas",
    layout: DefaultLayout,
    component: Recargas
  },
  {
    path: "/recarga",
    layout: DefaultLayout,
    component: Recarga
  },
  {
    path: "/conta",
    layout: DefaultLayout,
    component: Conta
  },
  {
    path: "/tutoriais",
    layout: DefaultLayout,
    component: Tutoriais
  }
];