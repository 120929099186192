/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import { 
    Container, Row, Col, Card, CardHeader, CardBody, 
    Button, Alert, FormInput, FormRadio, CardFooter
} from "shards-react";
import Cards from 'react-credit-cards';
import classNames from "classnames";

import PageTitle from "../components/common/PageTitle";
import Loader from '../components/Loader';
import api from '../service/ApiHandler';
import Toast from '../components/Toast';
import Auth from '../utils/Auth';
import Formater from '../utils/Formater';

const usuario_cobremais = localStorage.getItem('dXN1YXJpb19jb2JyZW1haXM=');

class Recarga extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            toast: false,
            toastTitle: '',
            toastContent: '',
            toastConfirm: null,
            toastCancel: null,
            toastSimple: false,
            toastTheme: '',
            loading: true,
            error: '',
            pacotes: [],
            tipoPagamento: 1,
            _cvc: '',
            _expiry: '',
            _name: '',
            _number: '',
            cvc: '',
            expiry: '',
            focus: '',
            name: '',
            number: '',
            validCard: false,
            validName: false,
            validExpiry: false,
            validCVC: false,
            pacote: 1,
            entendi: false
        };
        this.compraCartao = this.compraCartao.bind(this);
        this.compraBoleto = this.compraBoleto.bind(this);
        // Outros
        this.limparForms = this.limparForms.bind(this);
        this.openToast = this.openToast.bind(this);
        this.openConfirm = this.openConfirm.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
    }

    openToast({ title, content, confirm, theme }){ this.setState({ toast: true, toastTitle: title ? title : '', toastContent: content ? content : '', toastSimple: true, toastConfirm: confirm ? confirm : null, toastCancel: null, toastTheme: theme ? theme : null }); }
    openConfirm({ title, content, confirm, cancel, theme }){ this.setState({ toast: true, toastTitle: title ? title : 'Atenção', toastContent: content ? content : 'Você tem certeza?', toastSimple: false, toastConfirm: confirm ? confirm : null, toastCancel: cancel ? cancel : null, toastTheme: theme ? theme : null }); }
    showLoader(){ this.setState({ loading: true }); }
    hideLoader(){ this.setState({ loading: false }); }

    limparForms(){ }

    async componentDidMount(){
        await Auth();
        this.setState({ error: '' });
        const response = await api.listarPacotes();
        if(!response) this.setState({ error: 'Erro de conexão' });
        else if(response.error) this.setState({ error: response.error });
        else if(response.warning) this.setState({ error: response.warning });
        else if(response.pacotes){
            this.setState({ pacotes: response.pacotes });
        } else this.setState({ error: 'Erro de conexão' });
        setTimeout(() => this.hideLoader(), 500);
    }

    async compraCartao(){
        this.showLoader();
        const response = await api.pagamentoCartao({
           pacote: this.state.pacote,
           number: this.state.number,
           holder: this.state.name,
           expiry: this.state._expiry.replace(/ /g, ''),
           cvc: this.state.cvc,
           usuario: usuario_cobremais
        });
        setTimeout(() => {
            if(!response) {
                this.hideLoader();
                this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger' });
            } else if(response.error){
                this.hideLoader();
                this.openToast({ title: 'Atenção', content: response.error, theme: 'danger' });
            } else if(response.warning){
                this.hideLoader();
                this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning' });
            } else if(response.success){
                this.hideLoader();
                this.openToast({ title: 'Atenção', content: response.success, theme: 'success', confirm: () => { this.showLoader(); setTimeout(() => window.location.href = '/recargas', 2000); }});
            } else {
                this.hideLoader();
                this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger' });
            }
        }, 1500);
    }

    async compraBoleto(){
        this.showLoader();
        const response = await api.pagamentoBoleto({
           pacote: this.state.pacote,
           usuario: usuario_cobremais
        });
        setTimeout(() => {
            if(!response) {
                this.hideLoader();
                this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger' });
            } else if(response.error){
                this.hideLoader();
                this.openToast({ title: 'Atenção', content: response.error, theme: 'danger' });
            } else if(response.warning){
                this.hideLoader();
                this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning' });
            } else if(response.success){
                this.hideLoader();
                this.openToast({ title: 'Atenção', content: response.success, theme: 'success', confirm: () => { this.showLoader(); setTimeout(() => window.location.href = '/recargas', 2000); }});
            } else {
                this.hideLoader();
                this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger' });
            }
        }, 1500);
    }

    render() {

    return (
        <Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Toast show={this.state.toast} title={this.state.toastTitle} content={this.state.toastContent} confirm={this.state.toastConfirm} cancel={this.state.toastCancel} toggle={()=>this.setState({ toast: false })} simple={this.state.toastSimple} theme={this.state.toastTheme} />
            <Loader loading={this.state.loading} />
            { this.state.error ?
            <Alert className="mb-0" theme="danger" style={{marginLeft:-24,marginRight:-24}}>
                <i className="fa fa-exclamation-circle mx-2"></i> {this.state.error}
            </Alert> 
            : <></> }
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Recarga" subtitle="CobreMais" className="text-sm-left" />
            </Row>
            <Row>
                <Col lg={11}>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <h5 className="mb-0">Comprar Créditos</h5>
                        </CardHeader>
                        <CardBody style={{paddingBottom: 0}}>
                            <p className="mb-2">Escolha um pacote</p>
                            {
                                this.state.pacotes.length ?
                                <Row>
                                    {
                                        this.state.pacotes.map(row => (
                                            <Col key={row.id}>
                                                <div 
                                                    className={
                                                        classNames(
                                                            "pacote", 
                                                            "clickable",
                                                            (this.state.pacote === row.id ? "bg-dark" : "bg-primary")
                                                        )
                                                }    
                                                    onClick={() => this.setState({ pacote: row.id })}
                                                >
                                                    <span className="titulo">
                                                        {row.titulo}
                                                    </span>
                                                    <span className="valor">
                                                        {(row.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                    </span>
                                                    <FormRadio
                                                        name="pacote"
                                                        checked={this.state.pacote === row.id}
                                                        readOnly={true}
                                                    ></FormRadio>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row> :
                                <Alert theme="warning" size="sm" style={{borderRadius:'.6rem',marginBottom:'1rem'}}><i className="fa fa-exclamation-circle mx-2"></i> Nenhum pacote</Alert>
                            }
                        </CardBody>
                        {
                            this.state.pacote ?
                            <>
                                <CardBody className="border-top">
                                    <p className="mb-2">Metodo de Pagamento</p>
                                    <Row form>
                                        <Col sm={6} md={4} lg={3}>
                                            <FormRadio
                                                name="tipo_pagamento"
                                                checked={this.state.tipoPagamento === 1}
                                                onChange={() => this.setState({ tipoPagamento: 1})}
                                            >
                                                Cartão de Crédito
                                            </FormRadio>
                                        </Col>
                                        <Col sm={6} md={3} lg={3}>
                                            <FormRadio
                                                name="tipo_pagamento"
                                                checked={this.state.tipoPagamento === 2}
                                                onChange={() => this.setState({ tipoPagamento: 2, entendi: false })}
                                            >
                                                Boleto
                                            </FormRadio>
                                        </Col>
                                    </Row>
                                </CardBody>
                                {
                                    this.state.tipoPagamento === 1 ?
                                    <>
                                        <CardBody className="border-top">
                                            <Row form>
                                                <Col lg={6} style={{textAlign: 'center'}} className="card-container">
                                                    <Cards
                                                        cvc={this.state.cvc}
                                                        expiry={this.state.expiry}
                                                        focused={this.state.focus}
                                                        name={this.state.name}
                                                        number={this.state.number}
                                                        placeholders={{
                                                            name: 'NOME IMPRESSO'
                                                        }}
                                                        locale={{
                                                            valid: "valido até"
                                                        }}
                                                        callback={(t, v) => this.setState({ validCard: v })}
                                                    />
                                                </Col>
                                                <Col lg={{ size: 6, offset: 0 }} md={{ size: 8, offset: 2 }} className="card-form-container">
                                                    <FormInput 
                                                        placeholder="Número do Cartão" 
                                                        style={{marginBottom: 10}} 
                                                        maxLength="24"
                                                        invalid={!this.state.validCard}
                                                        value={this.state._number}
                                                        onChange={(e)=>{
                                                            this.setState({ 
                                                                number: Formater.cleanNumber(e.target.value),
                                                                _number: Formater.custom(e.target.value, '#### #### #### #### ####'),
                                                            });
                                                        }}
                                                        onFocus={()=>this.setState({focus:'number'})}
                                                        onBlur={()=>this.setState({focus:''})}
                                                    />
                                                    <FormInput 
                                                        placeholder="Nome Impresso no Cartão" 
                                                        style={{marginBottom: 10}}
                                                        value={this.state._name}
                                                        invalid={!this.state.validName}
                                                        onChange={(e)=>{
                                                            this.setState({ 
                                                                name: Formater.removeAccents(e.target.value).toUpperCase(),
                                                                _name: Formater.removeAccents(e.target.value).toUpperCase(),
                                                                validName: Formater.removeAccents(e.target.value).trim() ? true : false
                                                            });
                                                        }}
                                                        onFocus={()=>this.setState({focus:'name'})}
                                                        onBlur={()=>this.setState({focus:''})}
                                                    />
                                                    <Row>
                                                        <Col xs={6} style={{paddingRight: 5}}>
                                                            <FormInput 
                                                                placeholder="•• / ••••" 
                                                                maxLength="9" 
                                                                style={{marginBottom: 10}} 
                                                                value={this.state._expiry}
                                                                invalid={!this.state.validExpiry}
                                                                onChange={(e)=>{
                                                                    this.setState({ 
                                                                        expiry: Formater.cleanNumber(e.target.value),
                                                                        _expiry:  Formater.custom(e.target.value, '## / ####'),
                                                                        validExpiry: 
                                                                        (
                                                                            Formater.cleanNumber(e.target.value).length === 4 
                                                                                ||
                                                                            Formater.cleanNumber(e.target.value).length === 6
                                                                        ) && (
                                                                            (
                                                                                Formater.cleanNumber(e.target.value).substr(-2) ? Number(Formater.cleanNumber(e.target.value).substr(-2)) : 0
                                                                            ) >= Number(String(new Date().getFullYear()).substr(-2))
                                                                        )
                                                                        ? true : false
                                                                    });
                                                                }}
                                                                onFocus={()=>this.setState({focus:'expiry'})}
                                                                onBlur={()=>this.setState({focus:''})}
                                                            />
                                                        </Col>
                                                        <Col xs={6} style={{paddingLeft: 5}}>
                                                            <FormInput 
                                                                type="password"
                                                                placeholder="•••" 
                                                                maxLength="4" 
                                                                style={{marginBottom: 10}} 
                                                                value={this.state._cvc}
                                                                invalid={!this.state.validCVC}
                                                                onChange={(e)=>{
                                                                    this.setState({ 
                                                                        cvc: Formater.cleanNumber(e.target.value),
                                                                        _cvc: Formater.cleanNumber(e.target.value),
                                                                        validCVC: Formater.cleanNumber(e.target.value).length >= 3 ? true : false
                                                                    });
                                                                }}
                                                                onFocus={()=>this.setState({focus:'cvc'})}
                                                                onBlur={()=>this.setState({focus:''})}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter className="border-top" style={{textAlign: 'right'}}>
                                            <Button 
                                                theme="success" 
                                                disabled={
                                                    !this.state.validCard ||
                                                    !this.state.validName || 
                                                    !this.state.validExpiry ||
                                                    !this.state.validCVC
                                                }
                                                onClick={this.compraCartao}
                                            >Confirmar Compra</Button>
                                        </CardFooter>
                                    </>
                                    : 
                                    <>
                                        <CardBody className="border-top">
                                            <Alert className="mb-0" theme="info" style={{borderRadius: '.6rem'}}>
                                                <Row>
                                                    <Col md={10} sm={10} style={{paddingTop: 8, paddingBottom: 8}}>
                                                        <i className="fa fa-exclamation-circle mx-2"></i>
                                                        Caso o boleto não seja pago será protestado em cartório
                                                    </Col>
                                                    {
                                                        !this.state.entendi ?
                                                        <Col md={2} sm={2} style={{textAlign: 'right'}}>
                                                            <Button tiny block theme="dark" onClick={() => this.setState({ entendi: true })} style={{paddingLeft: 1, paddingRight: 1}}>Entendi</Button>
                                                        </Col> : <></>
                                                    }
                                                </Row>
                                            </Alert>
                                        </CardBody>
                                        {
                                            this.state.entendi ?
                                            <CardFooter className="border-top" style={{ textAlign: 'right' }}>
                                                <Button 
                                                    theme="success" 
                                                    onClick={this.compraBoleto}
                                                >Gerar Boleto</Button>
                                            </CardFooter> : <></>
                                        }
                                    </>
                                }
                            </> : <></>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
    );
  }
}

export default Recarga;
