import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import routes from "./routes";
import "./assets/App.css";
import "react-credit-cards/lib/styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "html5-device-mockups/dist/device-mockups.min.css";
import "./assets/shards-dashboards.1.1.0.min.css";
import "react-quill/dist/quill.snow.css";

var exists = false;
for(const route of routes){ if(route.path === window.location.pathname) exists = true }
if(!exists) window.location.href = "/error";

export default () => (
  <Router basename={process.env.REACT_APP_BASENAME || ""}>
    <div>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={props => {
              return (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              );
            }}
          />
        );
      })}
    </div>
  </Router>
);
