/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import {
  Container, Row,  Col, Card, CardHeader, CardBody, Button, Modal, 
  ModalBody, ModalHeader, InputGroup, InputGroupAddon, InputGroupText,
  FormInput, FormGroup, FormFeedback, FormTextarea, Alert, ModalFooter
} from "shards-react";
import ChatBubble from 'react-chat-bubble';
import stripHtml from 'string-strip-html';
import ReactQuill from 'react-quill';
import Speech from 'speak-tts';

import PageTitle from "../components/common/PageTitle";
import Loader from '../components/Loader';
import Formater from "../utils/Formater";
import api from '../service/ApiHandler';
import Toast from '../components/Toast';
import Auth from '../utils/Auth';

const speech = new Speech();

const usuario_cobremais = localStorage.getItem('dXN1YXJpb19jb2JyZW1haXM=');

class Templates extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            toast: false,
            toastTitle: '',
            toastContent: '',
            toastConfirm: null,
            toastCancel: null,
            toastSimple: false,
            toastTheme: '',
            loading: true,
            error: '',
            templates: [],
            idTemplate: '',
            criarTemplate: false,
            editarTemplate: false,
            titulo: '',
            validTitulo: true,
            criarEmail: false,
            criarLigacao: false,
            conteudoSMS: '',
            conteudoEmail: '',
            assuntoEmail: '',
            conteudoLigacao: '',
            validSMS: true,
            validEmail: true,
            validLigacao: true,
            pesquisar: '',
            validAssunto: true,
            editarSMS: false,
            editarEmail: false,
            editarLigacao: false
        };
        this.handleCadastrarTemplate = this.handleCadastrarTemplate.bind(this);
        this.handleEditarTemplate = this.handleEditarTemplate.bind(this);
        this.fecharSMS = this.fecharSMS.bind(this);
        this.fecharEmail = this.fecharEmail.bind(this);
        this.fecharLigacao = this.fecharLigacao.bind(this);
        this.editarTemplate = this.editarTemplate.bind(this);
        // Outros
        this.limparForms = this.limparForms.bind(this);
        this.openToast = this.openToast.bind(this);
        this.openConfirm = this.openConfirm.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.speak = this.speak.bind(this);

        speech.init({
            'volume': 1,
            'lang': 'pt-BR',
            'rate': 1,
            'pitch': 1,
            'splitSentences': true
        });
    }

    openToast({ title, content, confirm, theme }){ this.setState({ toast: true, toastTitle: title ? title : '', toastContent: content ? content : '', toastSimple: true, toastConfirm: confirm ? confirm : null, toastCancel: null, toastTheme: theme ? theme : null }); }
    openConfirm({ title, content, confirm, cancel, theme }){ this.setState({ toast: true, toastTitle: title ? title : 'Atenção', toastContent: content ? content : 'Você tem certeza?', toastSimple: false, toastConfirm: confirm ? confirm : null, toastCancel: cancel ? cancel : null, toastTheme: theme ? theme : null }); }
    showLoader(){ this.setState({ loading: true }); }
    hideLoader(){ this.setState({ loading: false }); }

    limparForms(){ this.setState({ titulo: '', conteudoSMS: '', conteudoLigacao: '', conteudoEmail: '', assuntoEmail: '' }); }

    async componentDidMount(){
        await Auth();
        this.setState({ error: '' });
        const response = await api.listarTemplates(usuario_cobremais);
        if(!response) this.setState({ error: 'Erro de conexão' });
        else if(response.error) this.setState({ error: response.error });
        else if(response.warning) this.setState({ error: response.warning });
        else if(response.templates){
            this.setState({ templates: response.templates });
        } else this.setState({ error: 'Erro de conexão' });
        setTimeout(() => this.hideLoader(), 500);
    }
    
    async editarTemplate(id){
        this.showLoader();
        const response = await api.obterTemplate({ id, usuario: usuario_cobremais });
        setTimeout(() => {
            if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger' });
            else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger' });
            else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning' });
            else if(response.template){
                this.setState({  
                    idTemplate: response.template.id,
                    titulo: response.template.titulo,
                    conteudoSMS: response.template.sms,
                    conteudoLigacao: response.template.ligacao
                });
                if(response.template.email) this.setState({ assuntoEmail: response.template.email.assunto, conteudoEmail: response.template.email.corpo });
                else this.setState({ assuntoEmail: '', conteudoEmail: '' });
                this.setState({ editarTemplate: true });
            } else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger' });
            this.hideLoader();
        }, 1500);
    }

    fecharSMS(){
        if(this.state.conteudoSMS && this.state.conteudoSMS.length <= 4){
            this.openToast({ title: 'Atenção', content: 'SMS inválido', theme: 'warning' });
            this.setState({ validSMS: false });
        } 
        else if(this.state.idTemplate) this.setState({ editarSMS: false, editarTemplate: true });
        else this.setState({ editarSMS: false, criarTemplate: true });
    }

    fecharLigacao(){
        if(this.state.conteudoLigacao && this.state.conteudoLigacao.length <= 4){
            this.openToast({ title: 'Atenção', content: 'Mensagem inválida', theme: 'warning' });
            this.setState({ validLigacao: false });
        } 
        else if(this.state.idTemplate) this.setState({ editarLigacao: false, editarTemplate: true });
        else this.setState({ editarLigacao: false, criarTemplate: true });
    }

    fecharEmail(){
        if(this.state.assuntoEmail && this.state.assuntoEmail.length <= 4){
            this.openToast({ title: 'Atenção', content: 'Assunto inválido', theme: 'warning' });
            this.setState({ validAssunto: false });
        } else if(stripHtml(this.state.conteudoEmail).result && stripHtml(this.state.conteudoEmail).result.length <= 4){
            this.openToast({ title: 'Atenção', content: 'Preencha o conteúdo do e-mail', theme: 'warning' });
            this.setState({ validEmail: false });
        } 
        else if(stripHtml(this.state.conteudoEmail).result && this.state.assuntoEmail.length <= 4) {
            this.openToast({ title: 'Atenção', content: 'Assunto inválido', theme: 'warning' });
            this.setState({ validAssunto: false });
        }
        else if(this.state.assuntoEmail && stripHtml(this.state.conteudoEmail).result.length <= 4) {
            this.openToast({ title: 'Atenção', content: 'Preencha o conteúdo do e-mail', theme: 'warning' });
            this.setState({ validEmail: false });
        }
        else if(this.state.idTemplate) this.setState({ editarEmail: false, editarTemplate: true });
        else this.setState({ editarEmail: false, criarTemplate: true });
    }

    speak(){
        if(speech.hasBrowserSupport()){
            speech.cancel();
            speech.speak({
                text: this.state.conteudoLigacao,
            }).then(() => {})
            .catch(e => {
                console.error(e);
                this.openToast({ title: 'Atenção', content: 'Erro ao reproduzir o áudio', theme: 'danger' });
            })
        } else this.openToast({ title: 'Atenção', content: 'Seu navegador não suporta essa função', theme: 'danger' });
    }

    async handleCadastrarTemplate() {
        if(!this.state.titulo || this.state.titulo.length <= 4) this.setState({ validTitulo: false });
        else if(
            !this.state.conteudoSMS && 
            !this.state.conteudoLigacao &&
            !stripHtml(this.state.conteudoEmail).result
        ) this.openToast({ title: 'Atenção', content: 'Você ainda não criou preencheu template', theme: 'warning' });
        else {
            this.showLoader();
            const response = await api.criarTemplate({ 
                titulo: this.state.titulo,
                sms: this.state.conteudoSMS ? this.state.conteudoSMS : '',
                ligacao: this.state.conteudoLigacao ? this.state.conteudoLigacao : '',
                email: this.state.assuntoEmail ? JSON.stringify({
                    assunto: this.state.assuntoEmail,
                    corpo: this.state.conteudoEmail
                }) : '',
                usuario: usuario_cobremais
            });
            this.setState({ criarTemplate: false });
            setTimeout(() => {
                if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ criarTemplate: true })});
                else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger', confirm: () => this.setState({ criarTemplate: true }) });
                else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning', confirm: () => this.setState({ criarTemplate: true }) });
                else if(response.success) this.openToast({ title: 'Atenção', content: response.success, theme: 'success', confirm: () => {window.location.reload();this.showLoader()}});
                else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ criarTemplate: true }) });
                setTimeout(() => this.hideLoader(), 500);
            }, 1500);
        }
    }

    async handleEditarTemplate() {
        if(!this.state.titulo || this.state.titulo.length <= 4) this.setState({ validTitulo: false });
        else if(
            !this.state.conteudoSMS && 
            !this.state.conteudoLigacao &&
            !stripHtml(this.state.conteudoEmail).result
        ) this.openToast({ title: 'Atenção', content: 'Você ainda não criou preencheu template', theme: 'warning' });
        else {
            this.showLoader();
            const response = await api.editarTemplate({
                id: this.state.idTemplate, 
                titulo: this.state.titulo,
                sms: this.state.conteudoSMS ? this.state.conteudoSMS : '',
                ligacao: this.state.conteudoLigacao ? this.state.conteudoLigacao : '',
                email: this.state.assuntoEmail ? JSON.stringify({
                    assunto: this.state.assuntoEmail,
                    corpo: this.state.conteudoEmail
                }) : '',
                usuario: usuario_cobremais
            });
            this.setState({ editarTemplate: false });
            setTimeout(() => {
                if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ editarTemplate: true })});
                else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger', confirm: () => this.setState({ editarTemplate: true }) });
                else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning', confirm: () => this.setState({ editarTemplate: true }) });
                else if(response.success) this.openToast({ title: 'Atenção', content: response.success, theme: 'success', confirm: () => {window.location.reload();this.showLoader()}});
                else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ editarTemplate: true }) });
                setTimeout(() => this.hideLoader(), 500);
            }, 1500);
        }
    }

    render() {

    return (
        <Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Toast show={this.state.toast} title={this.state.toastTitle} content={this.state.toastContent} confirm={this.state.toastConfirm} cancel={this.state.toastCancel} toggle={()=>this.setState({ toast: false })} simple={this.state.toastSimple} theme={this.state.toastTheme} />
            <Loader loading={this.state.loading} />
            <Modal size="sm" open={this.state.criarTemplate} backdrop={true} toggle={() => {}}>
                <ModalHeader>
                    Criar Template <i className="material-icons close-modal" onClick={() => { this.limparForms(); this.setState({criarTemplate: false}) }}>close</i>
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <label htmlFor="titulo_cadastro">Título</label>
                        <FormInput  
                            placeholder="..." 
                            required
                            id="titulo_cadastro" 
                            invalid={!this.state.validTitulo} 
                            value={this.state.titulo} 
                            onChange={(e)=> {
                                this.setState({ 
                                    titulo: Formater.capitalizer(e.target.value)
                                })
                                if(e.target.value && e.target.value.length > 4){
                                    this.setState({ 
                                        validTitulo: true
                                    })
                                } else {
                                    this.setState({ 
                                        validTitulo: false
                                    })
                                }
                            }} 
                            onBlur={(e) => {
                                if(e.target.value && e.target.value.length <= 4) this.setState({ validTitulo: false })
                                else this.setState({ validTitulo: true });
                            }}
                        />
                        <FormFeedback>Título Inválido</FormFeedback>
                    </FormGroup>
                    <table style={{width: '100%', marginTop: 25}}>
                        <tbody>
                            <tr>
                                <td style={{textAlign: 'center'}}>
                                    <strong>Template SMS</strong> 
                                </td>
                                <td style={{textAlign: 'center'}}>
                                    <Button theme={this.state.conteudoSMS?'primary':'success'} size="sm" outline onClick={() => this.setState({ editarSMS: true, criarTemplate: false })}>{this.state.conteudoSMS?'Editar':'Criar'}</Button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'center', paddingTop: 15}}>
                                    <strong>Template E-mail</strong> 
                                </td>
                                <td style={{textAlign: 'center', paddingTop: 15}}>
                                    <Button theme={this.state.conteudoEmail?'primary':'success'} size="sm" outline onClick={() => this.setState({ editarEmail: true, criarTemplate: false })}>{this.state.assuntoEmail?'Editar':'Criar'}</Button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'center', paddingTop: 15}}>
                                    <strong>Template Ligação</strong> 
                                </td>
                                <td style={{textAlign: 'center', paddingTop: 15}}>
                                    <Button theme={this.state.conteudoLigacao?'primary':'success'} size="sm" outline onClick={() => this.setState({ editarLigacao: true, criarTemplate: false })}>{this.state.conteudoLigacao?'Editar':'Criar'}</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ModalBody>
                <ModalFooter style={{display:'block',textAlign:'right'}}>
                    <Button theme="success" onClick={this.handleCadastrarTemplate}>Cadastrar</Button>
                </ModalFooter>
            </Modal>
            <Modal size="sm" open={this.state.editarTemplate} backdrop={true} toggle={() => {}}>
                <ModalHeader>
                    Editar Template <i className="material-icons close-modal" onClick={() => { this.limparForms(); this.setState({editarTemplate: false}) }}>close</i>
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <label htmlFor="titulo_cadastro">Título</label>
                        <FormInput  
                            placeholder="..." 
                            required
                            id="titulo_cadastro" 
                            invalid={!this.state.validTitulo} 
                            value={this.state.titulo} 
                            onChange={(e)=> {
                                this.setState({ 
                                    titulo: Formater.capitalizer(e.target.value)
                                })
                                if(e.target.value && e.target.value.length > 4){
                                    this.setState({ 
                                        validTitulo: true
                                    })
                                } else {
                                    this.setState({ 
                                        validTitulo: false
                                    })
                                }
                            }} 
                            onBlur={(e) => {
                                if(e.target.value && e.target.value.length <= 4) this.setState({ validTitulo: false })
                                else this.setState({ validTitulo: true });
                            }}
                        />
                        <FormFeedback>Título Inválido</FormFeedback>
                    </FormGroup>
                    <table style={{width: '100%', marginTop: 25}}>
                        <tbody>
                            <tr>
                                <td style={{textAlign: 'center'}}>
                                    <strong>Template SMS</strong> 
                                </td>
                                <td style={{textAlign: 'center'}}>
                                    <Button theme={this.state.conteudoSMS?'primary':'success'} size="sm" outline onClick={() => this.setState({ editarSMS: true, editarTemplate: false })}>{this.state.conteudoSMS?'Editar':'Criar'}</Button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'center', paddingTop: 15}}>
                                    <strong>Template E-mail</strong> 
                                </td>
                                <td style={{textAlign: 'center', paddingTop: 15}}>
                                    <Button theme={this.state.conteudoEmail?'primary':'success'} size="sm" outline onClick={() => this.setState({ editarEmail: true, editarTemplate: false })}>{this.state.assuntoEmail?'Editar':'Criar'}</Button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'center', paddingTop: 15}}>
                                    <strong>Template Ligação</strong> 
                                </td>
                                <td style={{textAlign: 'center', paddingTop: 15}}>
                                    <Button theme={this.state.conteudoLigacao?'primary':'success'} size="sm" outline onClick={() => this.setState({ editarLigacao: true, editarTemplate: false })}>{this.state.conteudoLigacao?'Editar':'Criar'}</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ModalBody>
                <ModalFooter style={{display:'block',textAlign:'right'}}>
                    <Button theme="success" onClick={this.handleEditarTemplate}>Salvar</Button>
                </ModalFooter>
            </Modal>
            <Modal size="lg" open={this.state.editarSMS} backdrop={true} toggle={() => {}}>
                <ModalHeader>
                    Template SMS
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <label htmlFor="sms">Conteúdo do SMS</label>
                        <FormTextarea  
                            placeholder="..." 
                            required
                            id="sms" 
                            invalid={!this.state.validSMS} 
                            value={this.state.conteudoSMS} 
                            rows={10}
                            onChange={(e)=> {
                                this.setState({ 
                                    conteudoSMS: Formater.removeAccents((e.target.value).replace(/\n/g, ' ').replace(/\s+/g,' '))
                                })
                                if(e.target.value && e.target.value.length <= 4){
                                    this.setState({ 
                                        validSMS: false
                                    })
                                } else {
                                    this.setState({ 
                                        validSMS: true
                                    })
                                }
                            }} 
                            onBlur={(e) => {
                                this.setState({ 
                                    conteudoSMS: Formater.removeAccents((e.target.value).replace(/\n/g, ' ').replace(/\s+/g,' ').trim())
                                })
                                if(e.target.value && e.target.value.length <= 4) this.setState({ validSMS: false });
                                else this.setState({ validSMS: true });
                            }}
                            maxLength={250}
                            style={{resize: 'none'}}
                        />
                        <FormFeedback>Mensagem Inválida</FormFeedback>
                    </FormGroup>
                    <Row form>
                        <Col xs={4} md={4}>
                            {this.state.conteudoSMS.trim().length} de 250
                        </Col>
                        <Col xs={8} md={8} className="text-right">
                            <Button theme={this.state.conteudoSMS?'success':'primary'} onClick={this.fecharSMS}>
                                {this.state.conteudoSMS?'Aceitar':'Fechar'}
                            </Button>
                        </Col>
                    </Row>
                    {
                        this.state.conteudoSMS ?
                        <center className="xs-hidden">
                            <hr />
                            <div className="device-wrapper" style={{maxWidth: 500}}>
                                <div className="device" data-device="iPhone5" data-orientation="landscape" data-color="black"  style={{width: '100%'}}>
                                    <div className="screen" style={{backgroundColor: 'white'}}>
                                        <ChatBubble 
                                            onNewMessage={() =>{}}
                                            messages = {[
                                                {
                                                    "type" : 1,
                                                    "image": require('../images/avatars/user.jpg'),
                                                    "text": this.state.conteudoSMS
                                                }
                                            ]} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </center>
                        : <></>
                    }
                </ModalBody>
            </Modal>
            <Modal size="lg" open={this.state.editarEmail} backdrop={true} toggle={() => {}}>
                <ModalHeader>
                    Template E-mail
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <label htmlFor="assunto">Assunto</label>
                        <FormInput  
                            placeholder="..." 
                            required
                            id="assunto" 
                            invalid={!this.state.validAssunto} 
                            value={this.state.assuntoEmail} 
                            onChange={(e)=> {
                                this.setState({ 
                                    assuntoEmail: e.target.value
                                })
                                if(e.target.value && e.target.value.length <= 4){
                                    this.setState({ 
                                        validAssunto: false
                                    })
                                } else {
                                    this.setState({ 
                                        validAssunto: true
                                    })
                                }
                            }} 
                            onBlur={(e) => {
                                this.setState({ assuntoEmail: (e.target.value).trim() });
                                if(e.target.value && e.target.value.length <= 4) this.setState({ validAssunto: false })
                                else this.setState({ validAssunto: true });
                            }}
                        />
                        <FormFeedback>Assunto Inválido</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                    <label>Conteúdo do E-mail</label>
                        <ReactQuill 
                            theme="snow" 
                            value={this.state.conteudoEmail}
                            modules={{
                                toolbar: [
                                    [{ 'header': [1, 2, false] }],
                                    ['bold', 'italic', 'underline','strike', 'blockquote', 'link', 'size', 'code'],
                                    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                    ['link', 'image'],
                                    ['clean']
                                ],
                            }}
                            formats={[
                                'header',
                                'bold', 'italic', 'underline', 'strike', 'blockquote',
                                'list', 'bullet', 'indent', 'align', 'direction',
                                'link', 'image', 'code-block'
                            ]}
                            className={!this.state.validEmail?'invalid-quill':''}
                            style={{height: 300, marginBottom: 60}}
                            onChange={(v) => {
                                this.setState({ conteudoEmail: v.trim() });
                                if(
                                    (stripHtml(v).result && stripHtml(v).result.length <= 4)
                                    ||
                                    (this.state.assuntoEmail && stripHtml(v).result.length <= 4)
                                )   this.setState({ validEmail: false });
                                else this.setState({ validEmail: true });
                            }}
                        />
                        <FormInput id="email" style={{display: 'none'}} invalid={!this.state.validEmail}  value={stripHtml(this.state.conteudoEmail).result} onChange={()=>{}} />
                        <FormFeedback>Preencha o conteúdo do E-mail</FormFeedback>
                    </FormGroup>
                    <Row form>
                        <Col xs={6} md={6} className="text-left">
                            <Button theme="warning" onClick={() => {
                                this.setState({ conteudoEmail: '' });
                                setTimeout(() => this.setState({ conteudoEmail: '' }), 500);
                            }}>
                                Limpar E-mail
                            </Button>
                        </Col>
                        <Col xs={6} md={6} className="text-right">
                            <Button theme={stripHtml(this.state.conteudoEmail).result||this.state.assuntoEmail?'success':'primary'} onClick={this.fecharEmail}>
                                {stripHtml(this.state.conteudoEmail).result||this.state.assuntoEmail?'Aceitar':'Fechar'}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal size="lg" open={this.state.editarLigacao} backdrop={true} toggle={() => {}}>
                <ModalHeader>
                    Template Ligação
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <label htmlFor="sms">Conteúdo da Ligação</label>
                        {
                            this.state.conteudoLigacao ?
                            <div style={{marginBottom: 15}}>
                                <Button theme="info" style={{marginRight: 10}} onClick={this.speak}>
                                    <img src={require('../images/play.svg')} alt="..." height="15" />
                                </Button>
                                <Button theme="warning" onClick={speech.cancel}>
                                    <img src={require('../images/stop.svg')} alt="..." height="15" />
                                </Button>
                            </div>
                            :
                            <></>
                        }
                        <FormTextarea  
                            placeholder="..." 
                            required
                            id="sms" 
                            invalid={!this.state.validLigacao} 
                            value={this.state.conteudoLigacao} 
                            rows={10}
                            onChange={(e)=> {
                                speech.cancel();
                                this.setState({ 
                                    conteudoLigacao: Formater.removeAccents((e.target.value).replace(/\n/g, ' ').replace(/\s+/g,' '))
                                })
                                if(e.target.value && e.target.value.length <= 4){
                                    this.setState({ 
                                        validLigacao: false
                                    })
                                } else {
                                    this.setState({ 
                                        validLigacao: true
                                    })
                                }
                            }} 
                            onBlur={(e) => {
                                this.setState({ 
                                    conteudoLigacao: Formater.removeAccents((e.target.value).replace(/\n/g, ' ').replace(/\s+/g,' ')).trim()
                                })
                                if(e.target.value && e.target.value.length <= 4) this.setState({ validLigacao: false });
                                else this.setState({ validLigacao: true });
                            }}
                            maxLength={250}
                            style={{resize: 'none'}}
                        />
                        <FormFeedback>Mensagem Inválida</FormFeedback>
                    </FormGroup>
                    <Row form>
                        <Col xs={4} md={4}>
                            {this.state.conteudoLigacao.trim().length} de 250
                        </Col>
                        <Col xs={8} md={8} className="text-right">
                            <Button theme={this.state.conteudoLigacao?'success':'primary'} onClick={() => { this.fecharLigacao(); speech.cancel(); }}>
                                {this.state.conteudoLigacao?'Aceitar':'Fechar'}
                            </Button>
                        </Col>
                    </Row>
                    <Alert theme="info" size="sm" style={{marginTop: 10,marginBottom: 0}}><i className="fa fa-exclamation-circle mx-2"></i> O sistema não permite o uso de assentos, utilize recursos ortográficos para formar as assentuações.</Alert>
                </ModalBody>
            </Modal>
            { this.state.error ?
            <Alert className="mb-0" theme="danger" style={{marginLeft:-24,marginRight:-24}}>
                <i className="fa fa-exclamation-circle mx-2"></i> {this.state.error}
            </Alert> 
            : <></> }
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Templates" subtitle="CobreMais" className="text-sm-left" />
            </Row>
            <Row>
                <Col md={12}>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <Row>
                                <Col md={3}>
                                    <h6 style={{ lineHeight: '38px', marginBottom: -3 }}>
                                        {this.state.templates.length} {this.state.templates.length===1?'Template':'Templates'}
                                    </h6>
                                </Col>
                                {
                                    this.state.templates.length ?
                                    <>
                                        <Col md={6}>
                                            <InputGroup>
                                                <InputGroupAddon type="prepend">
                                                    <InputGroupText><i className="material-icons">search</i></InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput placeholder="Pesquisar" onChange={(e)=>this.setState({ pesquisar: e.target.value })} />
                                            </InputGroup>
                                        </Col>
                                        <Col md={3} style={{textAlign: 'right'}}>
                                            <Button size="sm" className="margin-top-xs" theme="success" onClick={() => this.setState({ criarTemplate: true })}>Criar Novo</Button>
                                        </Col>
                                    </>
                                    :
                                    <Col md={9} style={{textAlign: 'right'}}>
                                        <Button size="sm" className="margin-top-xs" theme="success" onClick={() => this.setState({ criarTemplate: true })}>Criar Novo</Button>
                                    </Col>
                                }
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0 pb-3 table-responsive" style={{ maxHeight: 500 }}>
                            {
                                this.state.templates.length ?
                                <table className="table mb-0 table-middle table-hover" style={{marginBottom: 0}}>
                                    <thead className="bg-light">
                                        <tr>
                                            <th scope="col" className="border-0">
                                                Titulo
                                            </th>
                                            <th scope="col" className="border-0">
                                                Editado
                                            </th>
                                            <th scope="col" className="border-0" colSpan="4">
                                                Templates
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.templates.map(row => {
                                            var terms = `${row.titulo} ${row.alteracao?Formater.formatarAgo(row.alteracao):Formater.formatarAgo(row.criacao)}`;
                                            if(row.sms) terms += ' sms';
                                            if(row.ligacao) terms += ' ligacao';
                                            if(row.email) terms += ' e-mail email';
                                            if(terms) terms = terms.trim();
                                            if(terms) terms = Formater.lowerize(Formater.removeAccents(terms)).trim();
                                            var pesquisar = this.state.pesquisar;
                                            if(pesquisar) pesquisar = pesquisar.trim();
                                            if(pesquisar) pesquisar = Formater.lowerize(Formater.removeAccents(pesquisar)).trim();
                                            return terms.includes(pesquisar) || !pesquisar ? (
                                                <tr key={row.id} style={{opacity:row.status?'1':'.4'}}>
                                                    <td>{row.titulo}</td>
                                                    <td>{row.alteracao?Formater.formatarAgo(row.alteracao):Formater.formatarAgo(row.criacao)}</td>
                                                    <td style={{width: 80}}><i className={`material-icons ${row.sms?'text-success':'text-danger'}`}>fiber_manual_record</i> SMS</td>
                                                    <td style={{width: 90}}><i className={`material-icons ${row.email?'text-success':'text-danger'}`}>fiber_manual_record</i> E-mail</td>
                                                    <td style={{width: 100}}><i className={`material-icons ${row.ligacao?'text-success':'text-danger'}`}>fiber_manual_record</i> Ligação</td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <Button size="md" onClick={() => this.editarTemplate(row.id)}>Editar</Button>
                                                    </td>
                                                </tr>
                                            ) : (<></>)
                                        } )
                                    }
                                    </tbody>
                                </table>
                                :
                                <Alert theme="warning" style={{marginBottom: -16, borderBottomLeftRadius: '.625rem', borderBottomRightRadius: '.625rem', padding: '30px 10px', textAlign: 'center'}}>Nenhum template foi criado</Alert>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
  }
}

export default Templates;
