/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Alert, Badge } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Loader from '../components/Loader';
import Formater from "../utils/Formater";
import api from '../service/ApiHandler';
import Toast from '../components/Toast';
import Auth from '../utils/Auth';

const usuario_cobremais = localStorage.getItem('dXN1YXJpb19jb2JyZW1haXM=');

class Recargas extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            toast: false,
            toastTitle: '',
            toastContent: '',
            toastConfirm: null,
            toastCancel: null,
            toastSimple: false,
            toastTheme: '',
            loading: true,
            error: '',
            recargas: []
        };
        // Outros
        this.limparForms = this.limparForms.bind(this);
        this.openToast = this.openToast.bind(this);
        this.openConfirm = this.openConfirm.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
    }

    openToast({ title, content, confirm, theme }){ this.setState({ toast: true, toastTitle: title ? title : '', toastContent: content ? content : '', toastSimple: true, toastConfirm: confirm ? confirm : null, toastCancel: null, toastTheme: theme ? theme : null }); }
    openConfirm({ title, content, confirm, cancel, theme }){ this.setState({ toast: true, toastTitle: title ? title : 'Atenção', toastContent: content ? content : 'Você tem certeza?', toastSimple: false, toastConfirm: confirm ? confirm : null, toastCancel: cancel ? cancel : null, toastTheme: theme ? theme : null }); }
    showLoader(){ this.setState({ loading: true }); }
    hideLoader(){ this.setState({ loading: false }); }

    limparForms(){ }

    async componentDidMount(){
        await Auth();
        this.setState({ error: '' });
        const response = await api.listarRecargas(usuario_cobremais);
        if(!response) this.setState({ error: 'Erro de conexão' });
        else if(response.error) this.setState({ error: response.error });
        else if(response.warning) this.setState({ error: response.warning });
        else if(response.recargas){
            this.setState({ recargas: response.recargas });
        } else this.setState({ error: 'Erro de conexão' });
        setTimeout(() => this.hideLoader(), 500);
    }

    render() {

    return (
        <Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Toast show={this.state.toast} title={this.state.toastTitle} content={this.state.toastContent} confirm={this.state.toastConfirm} cancel={this.state.toastCancel} toggle={()=>this.setState({ toast: false })} simple={this.state.toastSimple} theme={this.state.toastTheme} />
            <Loader loading={this.state.loading} />
            { this.state.error ?
            <Alert className="mb-0" theme="danger" style={{marginLeft:-24,marginRight:-24}}>
                <i className="fa fa-exclamation-circle mx-2"></i> {this.state.error}
            </Alert> 
            : <></> }
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Recargas" subtitle="CobreMais" className="text-sm-left" />
            </Row>
            <Row>
                { this.state.error ?
                    <Col className="col-lg" md={12} sm={12}>
                        <Alert theme="danger" style={{borderRadius: 10}}>{this.state.error}</Alert>
                    </Col>
                    : <></>
                }
            </Row>
            <Row>
                <Col md={12}>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <Button size="sm" className="margin-top-xs" theme="success" href="/recarga">Comprar Créditos</Button>
                        </CardHeader>
                        <CardBody className="p-0 pb-3 table-responsive" style={{ maxHeight: 500 }}>
                            {
                                this.state.recargas.length ?
                                <table className="table mb-0 table-middle table-hover" style={{marginBottom: 0}}>
                                    <thead className="bg-light">
                                        <tr>
                                            <th scope="col" className="border-0">#</th>
                                            <th scope="col" className="border-0">Data da Compra</th>
                                            <th scope="col" className="border-0">Valor</th>
                                            <th scope="col" className="border-0">Envios</th>
                                            <th scope="col" className="border-0">Tipo</th>
                                            <th scope="col" className="border-0" style={{width: 150}}>Situação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.recargas.map(row => (
                                            <tr key={row.id}>
                                                <td>{row.id}</td>
                                                <td>{Formater.formatarDataHoraSemSegundos(row.criacao)}</td>
                                                <td>{row.tipo !== 5?(row.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }):'...'}</td>
                                                <td>{(row.envios).toLocaleString('pt-BR', { style: 'decimal', currency: 'BRL' })}</td>
                                                <td>
                                                    {row.tipo === 1 ? <><i className="fa fa-credit-card"></i> &nbsp; Cartão</> : ''}
                                                    {row.tipo === 2 ? <><i className="fa fa-barcode"></i>  &nbsp; Boleto</> : ''}
                                                    {row.tipo === 3 ? <><i className="fa fa-exchange"></i> &nbsp; Transferência</> : ''}
                                                    {row.tipo === 4 ? <><i className="fa fa-arrow-down"></i> &nbsp; Estorno</> : ''}
                                                    {row.tipo === 5 ? <><i class="fa fa-retweet" aria-hidden="true"></i> &nbsp; Pacote</> : ''}
                                                </td>
                                                <td>
                                                    {
                                                        row.status === 0 ? 
                                                        <>
                                                            {
                                                                row.tipo === 1 || row.tipo === 4 ?
                                                                <Badge theme="success" style={{ display: 'block', width: 150 }}>
                                                                    Processando Pagamento
                                                                </Badge> : <></>
                                                            }
                                                            {
                                                                row.tipo === 2 ?
                                                                <Button href={row.link} target="_blank" size="sm" block style={{ width: 150 }}>
                                                                    Pagar Boleto &nbsp; <i className="fa fa-arrow-right"></i>
                                                                </Button> : <></>
                                                            }
                                                            {
                                                                row.tipo === 3 ?
                                                                <Badge theme="success" style={{ display:'block', width:150 }}>
                                                                    Aguardando Pagamento
                                                                </Badge> : <></>
                                                            }
                                                        </>
                                                        : <></>
                                                    }
                                                    {
                                                        row.status === 1 && row.recarga && row.tipo !== 5 ? 
                                                        <Badge theme="success" style={{ display: 'block', width: 150, paddingTop: 8, paddingBottom: 8, fontSize: '.6875rem' }}>
                                                            Pago
                                                        </Badge> : <></>
                                                    }
                                                    {
                                                        row.status === 1 && !row.recarga ? 
                                                        <Badge theme="warning" style={{ display: 'block', width: 150, paddingTop: 8, paddingBottom: 8, fontSize: '.6875rem' }}>
                                                            Processando Pedido
                                                        </Badge> : <></>
                                                    }
                                                    {
                                                        row.status === 1 && row.recarga && row.tipo === 5 ? 
                                                        <Badge theme="success" style={{ display: 'block', width: 150, paddingTop: 8, paddingBottom: 8, fontSize: '.6875rem' }}>
                                                            Créditos Liberados
                                                        </Badge> : <></>
                                                    }
                                                    {
                                                        row.status === 2 ? 
                                                        <Badge theme="danger" style={{ display: 'block', width: 150, paddingTop: 8, paddingBottom: 8, fontSize: '.6875rem' }}>
                                                            Cancelado
                                                        </Badge> : <></>
                                                    }
                                                    {
                                                        row.status === 3 ? 
                                                        <Badge theme="danger" style={{ display: 'block', width: 150, paddingTop: 8, paddingBottom: 8, fontSize: '.6875rem' }}>
                                                            Estornado
                                                        </Badge> : <></>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                                :
                                <Alert theme="warning" style={{marginBottom: -16, borderBottomLeftRadius: '.625rem', borderBottomRightRadius: '.625rem', padding: '30px 10px', textAlign: 'center'}}>Nenhuma recarga foi realizada</Alert>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
  }
}

export default Recargas;
