export default function() {
  return [
    {
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""
    },
    {
      title: "Clientes",
      htmlBefore: '<i class="material-icons">group</i>',
      to: "/clientes",
    },
    {
      title: "Cobranças",
      htmlBefore: '<i class="material-icons">local_atm</i>',
      to: "/cobrancas",
    },
    {
      title: "Campanhas",
      htmlBefore: '<i class="material-icons">campaign</i>',
      to: "/campanhas",
    },
    {
      title: "Templates",
      htmlBefore: '<i class="material-icons">folder</i>',
      to: "/templates",
    },
    {
      title: "Recargas",
      htmlBefore: '<i class="material-icons">credit_card</i>',
      to: "/recargas",
    },
    {
      title: "Tutoriais",
      htmlBefore: '<i class="material-icons">support</i>',
      to: "/tutoriais",
    },
  ];
}
