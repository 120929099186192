import React from "react";
import {
  Card, CardHeader, Button, Container, CardBody, FormInput, CardFooter,
  Modal, ModalHeader, ModalBody, FormGroup, FormFeedback
} from "shards-react";

import Loader from '../components/Loader';
import Toast from '../components/Toast';
import api from '../service/ApiHandler';
import validate from "../utils/Validate";
import Formater from "../utils/Formater";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      toast: false,
      toastTitle: '',
      toastContent: '',
      toastConfirm: null,
      toastCancel: null,
      toastSimple: false,
      toastTheme: '',
      loading: false,
      logon: '',
      validLogon: true,
      senha: '',
      validSenha: true,
      esqueci: false,
      email: '',
      validEmail: true
    };
    this.openToast = this.openToast.bind(this);
    this.openConfirm = this.openConfirm.bind(this);
    this.showLoader = this.showLoader.bind(this);
    this.hideLoader = this.hideLoader.bind(this);
    this.efetuarLogin = this.efetuarLogin.bind(this);
    this.openEsqueci = this.openEsqueci.bind(this);
    this.recuperarSenha = this.recuperarSenha.bind(this);
  }
  
  openToast({ title, content, confirm, theme }){ this.setState({ toast: true, toastTitle: title ? title : '', toastContent: content ? content : '', toastSimple: true, toastConfirm: confirm ? confirm : null, toastCancel: null, toastTheme: theme ? theme : null }); }
  openConfirm({ title, content, confirm, cancel, theme }){ this.setState({ toast: true, toastTitle: title ? title : 'Atenção', toastContent: content ? content : 'Você tem certeza?', toastSimple: false, toastConfirm: confirm ? confirm : null, toastCancel: cancel ? cancel : null, toastTheme: theme ? theme : null }); }
  showLoader(){ this.setState({ loading: true }); }
  hideLoader(){ this.setState({ loading: false }); }

  componentDidMount(){
    var token_cobremais = localStorage.getItem('dG9rZW5fY29icmVtYWlz');
    var usuario_cobremais = localStorage.getItem('dXN1YXJpb19jb2JyZW1haXM=');
    if(token_cobremais && usuario_cobremais) window.location.href = '/login';
  }

  async efetuarLogin(){
    if(!this.state.validLogon || !this.state.logon) this.openToast({ title: 'Atenção', content: 'Informe seu logon', theme: 'warning' });
    else if(!this.state.validSenha || !this.state.senha) this.openToast({ title: 'Atenção', content: 'Informe sua senha', theme: 'warning' });
    else {
      this.showLoader();
      const response = await api.loginUsuario({ logon: this.state.logon, senha: this.state.senha });
      setTimeout(() => {
          if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.hideLoader()});
          else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger', confirm: () => this.hideLoader()});
          else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning', confirm: () => this.hideLoader()});
          else if(response.usuario) {
            localStorage.setItem("dG9rZW5fY29icmVtYWlz", response.token);
            localStorage.setItem("dXN1YXJpb19jb2JyZW1haXM=", response.usuario.id);
            window.location.href = "/tutoriais";
          } else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.hideLoader()});
      }, 1500);
    }
  }

  openEsqueci(){
    this.setState({esqueci: true});
  }

  async recuperarSenha() {
    if(!this.state.email || !this.state.validEmail) this.openToast({ title: 'Atenção', content: 'Informe um e-mail válido', theme: 'warning' });
    else {
      this.showLoader();
      const response = await api.recuperarSenha({ 
          email: this.state.email
      });
      this.setState({ esqueci: false });
      setTimeout(() => {
          if(!response) this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ esqueci: true })});
          else if(response.error) this.openToast({ title: 'Atenção', content: response.error, theme: 'danger', confirm: () => this.setState({ esqueci: true }) });
          else if(response.warning) this.openToast({ title: 'Atenção', content: response.warning, theme: 'warning', confirm: () => this.setState({ esqueci: true }) });
          else if(response.success) this.openToast({ title: 'Atenção', content: response.success, theme: 'success', confirm: () => this.setState({ email: '', esqueci: false })});
          else this.openToast({ title: 'Atenção', content: 'Erro de conexão', theme: 'danger', confirm: () => this.setState({ esqueci: true }) });
          setTimeout(() => this.hideLoader(), 500);
      }, 1500);
    }
  }

  render(){
    return (
      <Container fluid className="main-content-container px-4 vertical-center">
        <Loader loading={this.state.loading} />
        <Toast show={this.state.toast} title={this.state.toastTitle} content={this.state.toastContent} confirm={this.state.toastConfirm} cancel={this.state.toastCancel} toggle={()=>this.setState({ toast: false })} simple={this.state.toastSimple} theme={this.state.toastTheme} />
        <div>
          <Modal size="sm" open={this.state.esqueci} backdrop={true} toggle={() => this.setState({esqueci: false})}>
            <ModalHeader>Recuperar Senha</ModalHeader>
            <ModalBody>
              <FormGroup>
                <label htmlFor="email_esqueci">E-mail</label>
                <FormInput  
                    type="email"
                    placeholder="..." 
                    required
                    id="email_esqueci" 
                    invalid={!this.state.validEmail} 
                    value={this.state.email} 
                    onChange={(e)=> {
                        this.setState({ 
                          email: Formater.formatarEmail(e.target.value)
                        })
                        if(e.target.value && !validate.validateEmail(e.target.value)){
                            this.setState({ 
                              validEmail: false
                            })
                        } else {
                            this.setState({ 
                              validEmail: true
                            })
                        }
                    }} 
                    onBlur={(e) => {
                        if(!e.target.value) this.setState({ validEmail: true })
                    }}
                />
                <FormFeedback>E-mail Inválido</FormFeedback>
              </FormGroup>
              <Button theme="success" onClick={this.recuperarSenha}>Recuperar Senha</Button>
            </ModalBody>
          </Modal>
          <Card small>
            <CardHeader className="border-bottom text-center">
              <img src={require('../images/logo/logo-completo.svg')} style={{height: 30, width: '100%'}} alt="..." />
            </CardHeader>
            <CardBody>
              <label htmlFor="logon">Logon</label>
              <FormInput  
                type="tel"
                placeholder="..." 
                required
                id="logon" 
                invalid={!this.state.validLogon} 
                value={this.state.logon} 
                onChange={(e)=> {
                  this.setState({ 
                    logon: (e.target.value).replace(/\D/g, '')
                  })
                  if(e.target.value && e.target.value.length > 4){
                    this.setState({ 
                        validLogon: true
                    })
                  } else {
                    this.setState({ 
                      validLogon: false
                    })
                  }
                }} 
                onBlur={(e) => {
                  if(!e.target.value || e.target.value.length <= 4) this.setState({ validLogon: false })
                  else this.setState({ validLogon: true });
                }}
              />
              <br />
              <label htmlFor="senha">Senha</label>
              <FormInput  
                type="password"
                placeholder="..." 
                required
                id="senha" 
                invalid={!this.state.validSenha} 
                value={this.state.senha} 
                onChange={(e)=> {
                  this.setState({ 
                    senha: e.target.value
                  })
                  if(e.target.value && e.target.value.length > 4){
                    this.setState({ 
                      validSenha: true
                    })
                  } else {
                    this.setState({ 
                      validSenha: false
                    })
                  }
                }} 
                onBlur={(e) => {
                  if(!e.target.value || e.target.value.length <= 4) this.setState({ validSenha: false })
                  else this.setState({ validSenha: true });
                }}
              />
              <br />
              <center>
                <Button theme="success" onClick={this.efetuarLogin}>Acessar Conta</Button>
              </center>
            </CardBody>
            <CardFooter className="border-top text-center">
              <span className="clickable" style={{color: '#007bff'}} onClick={this.openEsqueci}>Esqueci minha senha?</span>
            </CardFooter>
          </Card>
        </div>
        <br />
        <center>
          {new Date().getFullYear()} © Rede Cred Auto
        </center>
      </Container>
    );
  }
}

export default Login;
