import api from '../service/ApiHandler';

export default async () => {
    var token_cobremais = localStorage.getItem('dG9rZW5fY29icmVtYWlz');
    var usuario_cobremais = localStorage.getItem('dXN1YXJpb19jb2JyZW1haXM=');
    if(token_cobremais && usuario_cobremais){
        const response = await api.validarToken(token_cobremais);
        if(!response) return false;
        else if(response.logout){
            localStorage.removeItem('dG9rZW5fY29icmVtYWlz');
            localStorage.removeItem('dXN1YXJpb19jb2JyZW1haXM=');
            window.location.href = '/login';
        }
    } else window.location.href = '/login';
};