import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import Toast from '../../../../components/Toast';
import api from '../../../../service/ApiHandler';

const token_cobremais = localStorage.getItem('dG9rZW5fY29icmVtYWlz');

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toast: false,
      toastTitle: '',
      toastContent: '',
      toastConfirm: null,
      toastCancel: null,
      toastSimple: false,
      toastTheme: '',
      visible: false,
      usuario: '?'
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
    this.sair = this.sair.bind(this);
    this.openToast = this.openToast.bind(this);
    this.openConfirm = this.openConfirm.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  openToast({ title, content, confirm, theme }){ this.setState({ toast: true, toastTitle: title ? title : '', toastContent: content ? content : '', toastSimple: true, toastConfirm: confirm ? confirm : null, toastCancel: null, toastTheme: theme ? theme : null }); }
  openConfirm({ title, content, confirm, cancel, theme }){ this.setState({ toast: true, toastTitle: title ? title : 'Atenção', toastContent: content ? content : 'Você tem certeza?', toastSimple: false, toastConfirm: confirm ? confirm : null, toastCancel: cancel ? cancel : null, toastTheme: theme ? theme : null }); }

  async componentDidMount() {
    var response = await api.obterUsuarioToken(token_cobremais);
    if(response){
      if(response.usuario){
        this.setState({ usuario: response.usuario.nome });
      }
    }
  }

  sair (){
    this.openConfirm({ title: 'Atenção', content: 'Você tem certeza?', confirm: () => {
      localStorage.removeItem('dG9rZW5fY29icmVtYWlz');
      localStorage.removeItem('dXN1YXJpb19jb2JyZW1haXM=');
      window.location.href = '/login';
    }});
  }

  render() {
    return (
      <>
        <Toast show={this.state.toast} title={this.state.toastTitle} content={this.state.toastContent} confirm={this.state.toastConfirm} cancel={this.state.toastCancel} toggle={()=>this.setState({ toast: false })} simple={this.state.toastSimple} theme={this.state.toastTheme} />
        <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
          <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
            <img
              className="user-avatar rounded-circle mr-2"
              src={require("./../../../../images/avatars/user.jpg")}
              alt="User Avatar"
            />{" "}
            <span className="d-none d-md-inline-block">{this.state.usuario}</span>
          </DropdownToggle>
          <Collapse tag={DropdownMenu} right small open={this.state.visible}>
            <DropdownItem tag="a" href="/conta">
              <i className="material-icons">account_circle</i> Editar Conta
            </DropdownItem>
            <DropdownItem tag="a" className="text-danger" onClick={this.sair}>
              <i className="material-icons text-danger">&#xE879;</i> Sair
            </DropdownItem>
          </Collapse>
        </NavItem>
      </>
    );
  }
}
