import React from "react";
import {
  Card, CardBody
} from "shards-react";
import api from '../../../service/ApiHandler';

const usuario_cobremais = localStorage.getItem('dXN1YXJpb19jb2JyZW1haXM=');

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            saldo: null,
            error: true
        }
    }

    async componentDidMount(){
        const response = await api.consultarSaldo(usuario_cobremais);
        if(!response) this.setState({ error: true });
        else if(response.error) this.setState({ error: true });
        else if(response.warning) this.setState({ error: true });
        else if(response.saldo) this.setState({ saldo: response.saldo.saldo, error: false });
        else this.setState({ error: true });
    }

    render(){
        return (
            <div className={this.props.type==='desktop'?'main-navbar__search w-100 d-none d-md-flex d-lg-flex':'d-md-none d-lg-none'}>
                <div seamless className="ml-3">
                    <div style={{paddingTop: 7}}>
                        {
                            !this.state.error ?
                                <Card small className="no-shadow">
                                    <CardBody 
                                        className={((this.state.saldo ? this.state.saldo : 0)?'bg-primary':'bg-danger') + ' clickable'}
                                        style={{
                                            paddingTop: 0, 
                                            paddingBottom: 5,
                                            borderRadius: '.25rem',
                                            color: 'white',
                                            paddingLeft: 10,
                                            paddingRigth: 10
                                        }}
                                        onClick={() => window.location.href='/recarga'}
                                    >
                                        <small>Saldo</small>
                                        <h6  
                                            className="stats-small__value count saldo-stat" 
                                            style={{
                                                color: 'white',
                                                lineHeight: 0,
                                                fontSize: 18
                                            }}
                                        >
                                            {(this.state.saldo ? this.state.saldo : 0).toLocaleString('pt-BR', { style: 'decimal', currency: 'BRL', minimumFractionDigits: 0,  })}
                                        </h6>
                                    </CardBody>
                                </Card>
                            : <></>
                        }
                    </div>
                </div>
            </div>
        );
    }
}